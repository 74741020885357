import React from 'react'
import "./UpcomingEventsStyle.css";
import EventImage from "../assets/enroll-banner.webp"



const UpcomingEvents = () => {
  return (
    <div>
        <section className="eventsSection text-center pbtm lightBg">
      <p className="title fw600 poppins textDark text-capitalize">
        silva method india
      </p>
      <h2 className="h2 fw700 poppins textDark text-capitalize">
        upcoming <span className="gradientText">events</span>
      </h2>
      <a href="#" className="enrollBanner">
        <img src={EventImage} alt="#" loading="lazy" className="w100 h100" />
      </a>
    </section>
    </div>
  )
}

export default UpcomingEvents