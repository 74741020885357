import React, { useEffect, useState } from 'react'
import './HealthHeroStyle.css'
import HeroBGImg from "../assets/health-banner-web.webp"
import HeroBGMobileImg from "../assets/health-banner-mob.webp"
import HeroBGTabImg from "../assets/health-banner-tab.webp"
import RazorpayImg from "../assets/RAZORPAY-ICON.webp"
import axios from 'axios';
import { Link } from 'react-scroll';
import useRazorpay from "react-razorpay";

const HealthHero = () => {

      //---------------code for form data submit----------------
 
    const [formData, setFormData] = useState({
        fname: '',
        mobile: '',
        email: '',
        timeslot: ''

    });    
 
  const [error, setError] = useState('');
  console.log('formdata', formData)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.fname || !formData.mobile || !formData.email || !formData.timeslot) {
      setError('All fields are required');
      return;
    }

    try {
      console.log('i am inside try');
      console.log('formdata', formData)
      // const response = await axios.post(
      //   'https://dedicated-nurture-a182b31812.strapiapp.com/api/landing-pages',
      //   {    
      //     data: {
      //       Name: formData.fname,
      //       Phone: formData.mobile,
      //       Email: formData.email,
      //       TimeSlot: formData.timeslot
      //     }
      //   },
      //   {
      //     headers: {
      //       Authorization: 'Bearer b22c667d5e14a86c5f9c3fbbc9489b9028e05b3d7263a8c2531403ddfb91aaf51b0a30a88b2da909549932e07b125430f7ffc13b3f7ec4b88910fc498dfb0f04778127e33bfdb99543f9e678a1211b88cae385c64d7e93610f0aa6dd983b2c7bea7556c27e1cfc0cafeed6f671ced98b9fa7f5d8e6c501a86df53edf50521999'
            
      //     }
      //   }
      // );

      const response = await fetch('https://silva-india-dashboard-backend.onrender.com/api/v1/leads/submit-healthpage-lead',{
        method: "POST",
        body: JSON.stringify({"name":formData.fname,
            "email":formData.email,
            "phone":formData.mobile,
            "timeslot":formData.timeslot
                                  }),
        headers:{
              "Content-Type": "application/json",
        }
       });
        


      console.log('Response:', response);
      // Reset form fields after successful submission
    //   launchRazorpayPayment11(formData);
    // await handlePayment();
      setFormData({
        fname: '',
        mobile: '',
        email: '',
        timeslot: ''
      });
      setError('');
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred, please try again later.');
    }
  };

// ------------code for payment--------------------------------
const [Razorpay] = useRazorpay();
const handlePayment= async()=>{
  try {
    const createOrder= await fetch('http://localhost:5000/api/v1/orders/create-order',{
      method: "POST",
      body: JSON.stringify({
          product_id:"Product99",
          user_id:"User111",
          purchase_type:"course"
      }),
      headers:{
            "Content-Type": "application/json",
      }
    });
    const order= await createOrder.json();
    // console.log(order,"order created") 
    if(order?.order_id){
      var options= {
        "key": "rzp_test_RJvswW0ADyeF2K",
        name:"Payment",
        description:"Payment Method",
        image:"https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?cs=srgb&dl=pexels-anjana-c-169994-674010.jpg&fm=jpg",
        order_id: order?.order_id,
        handler: async function (response) {
          if(response?.razorpay_payment_id){
             const capturePayment= await fetch('http://localhost:5000/api/v1/orders/capture-order',{
              method: "POST",
              body: JSON.stringify({
                "paymentId": response?.razorpay_payment_id,
            }),
              headers:{
                    "Content-Type": "application/json",
              }
             });
             const captureRes= await capturePayment.json();
            //  console.log(captureRes,'captureRes')
          }
        },
        "modal": {
          "ondismiss": async function(){
              // console.log(res,"failed response");
              const capt= await fetch('http://localhost:5000/api/v1/orders/capture-failed-payment',{
                method: "POST",
                body: JSON.stringify({
                "order_id": order?.order_id,
                }),
                headers:{
                  "Content-Type": "application/json",
                }
              });
              const captRes= await capt.json();
              console.log(captRes,'failed res')
          }
      },
        prefill: {
          name: "Sukanta Dolai",
          email: "youremail@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      }
  
      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        console.log(response.error.code);
        console.log(response.error.description);
        console.log(response.error.source);
        console.log(response.error.step);
        console.log(response.error.reason);
        console.log(response.error.metadata.order_id);
        console.log(response.error.metadata.payment_id);
      });
    
      rzp1.open();
    }
    // setApiOrder(order); 
  } catch (error) {
    console.log("error:",error);
  }
 
  // console.log(process.env.REACT_APP_RAZORPAY_KEY,"payment key")
};

// ----------code for payment ends------------------------------

  //---------------------------------------------------------

  const isFormFilled = Object.values(formData).every((value) => value.trim() !== '');
  useEffect(() => {
    const btn = document.getElementById("btnSubmit");
    if (!isFormFilled) {
      btn.style.opacity = 0.4
    } else {
      btn.style.opacity = 1
    }
  }, [isFormFilled])

  const handleScroll = () => {
    const rightForm = document.querySelector(".rightForm");
    // console.log(doc, "document")
    if (rightForm) {
      rightForm.scrollIntoView({
        behavior: "smooth"
      })
    }
    setTimeout(() => {
      rightForm.classList.remove("pulseAnim");
      rightForm.classList.remove("pulseShadow");
    }, 4000)
    rightForm.classList.add("pulseAnim");
    rightForm.classList.add("pulseShadow");
  }

  const [isChecked, setIsChecked] = useState(true); // Set to true to make it checked by default

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state when the checkbox is clicked
  };

    return (
        <>
            <section className="heroSection position-relative healthHeroPage">
                <img src={HeroBGMobileImg} className="d-md-none d-block w-100" alt="#" loading="lazy" />
                <img src={HeroBGTabImg} className="d-none d-md-block d-lg-none w-100" alt="#" loading="lazy" />
                <img src={HeroBGImg} className="d-none d-lg-block w-100 bannerLg" alt="#" loading="lazy" />
                <div className="carousel-caption">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center totalContArea">
                            <div className="heroCont">
                                <h1 class="h1 fw700 poppins colorYellow d-lg-none">
                                    সিলভা মেথড হেলথ ম্যানেজমেন্ট মাস্টারক্লাস মাত্র ৯৯ টাকায়
                                    <p class="title fw400 subTitle mb-0 primaryWhite mt-3">
                                        আপনার অভ্যন্তরীণ চিকিৎসা শক্তির উন্মোচন করুন
                                    </p>
                                </h1>
                                <h1 class="h1 fw700 poppins primaryWhite d-none d-lg-block">
                                    সিলভা মেথড হেলথ ম্যানেজমেন্ট মাস্টারক্লাস মাত্র ৯৯ টাকায়
                                    <p class="title fw400 subTitle mb-0 primaryWhite mt-3">
                                        আপনার অভ্যন্তরীণ চিকিৎসা শক্তির উন্মোচন করুন
                                    </p>
                                </h1>
                                <div className="classTiming">
                                    <ul className='resetUl'>
                                        <li class="d-flex align-items-start gap-2 mb-3">
                                            {/* <img src="/static/media/check-dark.7a01ddc7f7b6b932418881c939b7606e.svg" alt="#" loading="lazy" className='circleTick' /> */}
                                            <span><i class="fa-solid fa-calendar-days"></i></span>
                                            <p class="primaryWhite roboto mb-0">২২ শে জুন</p>
                                        </li>
                                        <li class="d-flex align-items-start gap-2 mb-3">
                                            <span><i class="fa-regular fa-clock"></i></span>
                                            <p class="primaryWhite roboto mb-0">
                                                সকাল ১০ টা থেকে দুপুর ১২ টা ( ভারতীয় সময়)
                                            </p>
                                        </li>
                                        <li class="d-flex align-items-start gap-2 mb-0">
                                            <span><i class="fa-regular fa-clock"></i></span>
                                            <p class="primaryWhite roboto mb-0">
                                                সন্ধ্যে ৬ঃ৩০ টা থেকে রাত  ৮:৩০ টা ( ভারতীয় সময়)
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <p className="fw4300 roboto mt-4 mb-4">
                                    দীর্ঘদিন ধরে আপনি কি শারীরিক বা মানসিক অসুস্থতায় ভুগছেন? আপনার উপার্জিত অর্থের অনেকটায়
                                    কি ওষুধের জন্য ব্যয় হয়ে যাচ্ছে তবুও আপনি সুস্থ ও স্বাভাবিক জীবন যাপন করতে পারছেন না? আপনার শারীরিক ও মানসিক সুস্থতার সহজ উপায় হল - সিলভা মেথড।
                                </p>
                                <Link to="masterClassForm_health" smooth={true} duration={500}>
                                <a href="#" class="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">এখনি রেজিস্টার করুন</a>
                                </Link>
                            </div>
                            <div className="rightForm" id="masterClassForm_health">
                                <div class="div1">
                                    <p class="fw700 poppins primaryBlack mb-1 p1">
                                        হেলথ ম্যানেজমেন্ট মাস্টারক্লাস
                                    </p>
                                    <span class="primaryBlack fw500 date">
                                        তারিখ <span class="fw500 shapeRect gradientBg primaryWhite">২২শে জুন</span>
                                    </span>
                                    <span class="primaryBlack fw500 ms-4">
                                        সময়কাল <span class="fw500 shapeRect gradientBg primaryWhite">২ ঘণ্টা</span>
                                    </span>
                                </div>
                                <form onSubmit={handleSubmit} >
                                    {error && <div className="error">{error}</div>}
                                    <div className="row gy-4">
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="userName" name="fname" value={formData.fname} onChange={handleChange} placeholder="Name" />
                                        </div>
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="userPhn" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="Phone No." />
                                        </div>
                                        <div className="col-12">
                                            <input type="email" className="form-control" id="userEmail" name="email" value={formData.email} onChange={handleChange} placeholder="Email ID" />
                                        </div>
                                        <div className="col-12">
                                            <select className="form-select form-control" id="selectTag" name="timeslot" value={formData.timeslot} onChange={handleChange} aria-label="Default select example">
                                                <option value="" selected disabled>Book your timeslot</option>
                                                <option value="1">10 a.m. - 12 p.m.(IST) / 10:30 a.m. - 12:30 p.m.(BDT)</option>
                                                <option value="2">6:30 p.m. - 8:30 p.m.(IST) / 7p.m. - 9 p.m. (BDT)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap column-gap-4 row-gap-1 mt-3 mb-4 mb-md-3">
                                        <p className="p2 fw900 roboto textDark d-flex align-items-center justify-content-center mb-0">
                                            <span className="me-1">₹</span>
                                            <span className="roboto">৯৯/-</span>
                                        </p>
                                        <p className="p2 fw900 oldPrice textLight mb-0">
                                            <span className="me-1">₹</span>
                                            <span className="roboto">১৯৮</span>
                                        </p>
                                        <p className="fw600 roboto textDark mb-0">
                                            You will get 50% Off
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap column-gap-3 mb-4 paymentWidget" role="button">
                                        <span className="fw600 textDark">Pay Via</span>
                                        <img src={RazorpayImg} alt="#" loading="lazy" />
                                    </div>
                                    <ul class="resetUl">
                                        <li class="d-flex align-items-start gap-2 mb-2">
                                            <div class="form-check">
                                                {/* <input class="form-check-input" type="checkbox" value=""
                          id="flexCheckChecked" checked />
                        <label class="form-check-label" for="flexCheckChecked">
                          <p class="textDark roboto mb-0 smFont">
                            I accept your <a href="#" class="fw500 textDark">Terms &
                              Conditions</a> and willingly agree to receive Marketing emails.
                          </p>
                        </label> */}
                                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="flexCheckChecked"
                                                    // checked={isChecked} // Set checked attribute based on the state
                                                    // onChange={handleCheckboxChange} // Call the function to handle checkbox change
                                                    />
                                                    <p className="textDark roboto mb-0 smFont">
                                                        I accept your <a href="#" className="fw700 textDark">Terms & Conditions</a> and willingly agree to receive Marketing emails.
                                                    </p>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                    <button type="submit" id="btnSubmit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0">
                                        রেজিস্টার করুন
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HealthHero