import React from 'react'
import "./ProblemsStyle.css";
import SelfVictimizationImg from "../assets/_Self-Victimization.svg"
import EmotionalImbalanceImg from "../assets/_Emotional Imbalance.svg"
import RelationshipIssuesImg from "../assets/_Relationship Issues.svg"
import ProcrastinationImg from "../assets/_Procrastination.svg"
import MoneyProblemImg from "../assets/_Money Problem.svg"
import AngerIssuesImg from "../assets/_Anger Issue.svg"
import ChildhoodTraumaImg from "../assets/_Childhood Trauma.svg"
import LowEsteemImg from "../assets/_Low self-esteem.svg"
import InsomniaImg from "../assets/_Insomnia.svg"
import BadhabitsImg from "../assets/_Bad Habits.svg"
import LonelinessImg from "../assets/_Loneliness.svg"
import PoorMemoryImg from "../assets/_Poor Memory.svg"

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Problems = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 12,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          rows: 2
        }
      }
    ]
  };


  return (
    <div>
      <section className="problemSection lightBg pbtm">
        <div className="container">
          <div className="problemCont text-center">
            <h2 className="h2 fw700 poppins textDark">
              Overcome Various <span className="gradientText">Problems</span>
            </h2>
            <div class="d-none d-lg-block">
              <div className="row gy-4">
                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="prblmCard">
                    <div className="icon">
                      <img src={SelfVictimizationImg} alt="#" loading="lazy" />
                    </div>
                    <div className="textCont">
                      <p className="fw600 poppins textDark text-capitalize m-0">Self Victimization</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="prblmCard">
                    <div className="icon">
                      <img src={EmotionalImbalanceImg} alt="#" loading="lazy" />
                    </div>
                    <div className="textCont">
                      <p className="fw600 poppins textDark text-capitalize m-0">Emotional Imbalance</p>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="prblmCard">
                    <div className="icon">
                      <img src={RelationshipIssuesImg} alt="#" loading="lazy" />
                    </div>
                    <div className="textCont">
                      <p className="fw600 poppins textDark text-capitalize m-0">Relationship Issues</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="prblmCard">
                    <div className="icon">
                      <img src={ProcrastinationImg} alt="#" loading="lazy" />
                    </div>
                    <div className="textCont">
                      <p className="fw600 poppins textDark text-capitalize m-0">Procrastination</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="prblmCard">
                    <div className="icon">
                      <img src={MoneyProblemImg} alt="#" loading="lazy" />
                    </div>
                    <div className="textCont">
                      <p className="fw600 poppins textDark text-capitalize m-0">Money Problem</p>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="prblmCard">
                    <div className="icon">
                      <img src={AngerIssuesImg} alt="#" loading="lazy" />
                    </div>
                    <div className="textCont">
                      <p className="fw600 poppins textDark text-capitalize m-0">Anger Issues</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="prblmCard">
                    <div className="icon">
                      <img src={ChildhoodTraumaImg} alt="#" loading="lazy" />
                    </div>
                    <div className="textCont">
                      <p className="fw600 poppins textDark text-capitalize m-0">Childhood Trauma</p>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="prblmCard">
                    <div className="icon">
                      <img src={LowEsteemImg} alt="#" loading="lazy" />
                    </div>
                    <div className="textCont">
                      <p className="fw600 poppins textDark text-capitalize m-0">Low Self Esteem</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="prblmCard">
                    <div className="icon">
                      <img src={InsomniaImg} alt="#" loading="lazy" />
                    </div>
                    <div className="textCont">
                      <p className="fw600 poppins textDark text-capitalize m-0">Insomnia</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="prblmCard">
                    <div className="icon">
                      <img src={BadhabitsImg} alt="#" loading="lazy" />
                    </div>
                    <div className="textCont">
                      <p className="fw600 poppins textDark text-capitalize m-0">Bad Habits</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="prblmCard">
                    <div className="icon">
                      <img src={LonelinessImg} alt="#" loading="lazy" />
                    </div>
                    <div className="textCont">
                      <p className="fw600 poppins textDark text-capitalize m-0">Loneliness</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="prblmCard">
                    <div className="icon">
                      <img src={PoorMemoryImg} alt="#" loading="lazy" />
                    </div>
                    <div className="textCont">
                      <p className="fw600 poppins textDark text-capitalize m-0">Poor Memory</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container container2 d-lg-none'>
          <Slider {...settings}>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="prblmCard">
                <div className="icon">
                  <img src={SelfVictimizationImg} alt="#" loading="lazy" />
                </div>
                <div className="textCont">
                  <p className="fw600 poppins textDark text-capitalize m-0">Self Victimization</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="prblmCard">
                <div className="icon">
                  <img src={EmotionalImbalanceImg} alt="#" loading="lazy" />
                </div>
                <div className="textCont">
                  <p className="fw600 poppins textDark text-capitalize m-0">Emotional Imbalance</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="prblmCard">
                <div className="icon">
                  <img src={RelationshipIssuesImg} alt="#" loading="lazy" />
                </div>
                <div className="textCont">
                  <p className="fw600 poppins textDark text-capitalize m-0">Relationship Issues</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="prblmCard">
                <div className="icon">
                  <img src={ProcrastinationImg} alt="#" loading="lazy" />
                </div>
                <div className="textCont">
                  <p className="fw600 poppins textDark text-capitalize m-0">Procrastination</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="prblmCard">
                <div className="icon">
                  <img src={MoneyProblemImg} alt="#" loading="lazy" />
                </div>
                <div className="textCont">
                  <p className="fw600 poppins textDark text-capitalize m-0">Money Problem</p>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="prblmCard">
                <div className="icon">
                  <img src={AngerIssuesImg} alt="#" loading="lazy" />
                </div>
                <div className="textCont">
                  <p className="fw600 poppins textDark text-capitalize m-0">Anger Issues</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="prblmCard">
                <div className="icon">
                  <img src={ChildhoodTraumaImg} alt="#" loading="lazy" />
                </div>
                <div className="textCont">
                  <p className="fw600 poppins textDark text-capitalize m-0">Childhood Trauma</p>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="prblmCard">
                <div className="icon">
                  <img src={LowEsteemImg} alt="#" loading="lazy" />
                </div>
                <div className="textCont">
                  <p className="fw600 poppins textDark text-capitalize m-0">Low Self Esteem</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="prblmCard">
                <div className="icon">
                  <img src={InsomniaImg} alt="#" loading="lazy" />
                </div>
                <div className="textCont">
                  <p className="fw600 poppins textDark text-capitalize m-0">Insomnia</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="prblmCard">
                <div className="icon">
                  <img src={BadhabitsImg} alt="#" loading="lazy" />
                </div>
                <div className="textCont">
                  <p className="fw600 poppins textDark text-capitalize m-0">Bad Habits</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="prblmCard">
                <div className="icon">
                  <img src={LonelinessImg} alt="#" loading="lazy" />
                </div>
                <div className="textCont">
                  <p className="fw600 poppins textDark text-capitalize m-0">Loneliness</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="prblmCard">
                <div className="icon">
                  <img src={PoorMemoryImg} alt="#" loading="lazy" />
                </div>
                <div className="textCont">
                  <p className="fw600 poppins textDark text-capitalize m-0">Poor Memory</p>
                </div>
              </div>
            </div>

            {/* Add more cards */}
          </Slider>
        </div>
      </section>



    </div >
  )
}

export default Problems