import React from 'react'
import './CourseCurriculumStyle.css'

const CourseCurriculum = () => {
    return (
        <>
            <section className='CourseCurriculum lightBg ptop pbtm'>
                <div className='container'>
                    <h2 className="h2 fw700 poppins textDark text-center mb-4 mb-lg-5">
                        About The <span className="gradientText">Course Curriculum
                        </span>
                    </h2>
                    <div className='curriculumTabs'>
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-one-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one"
                                    aria-selected="true">
                                    <h3 class="title roboto mb-0">
                                        Chapters
                                    </h3>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-two-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-two" type="button" role="tab" aria-controls="pills-two"
                                    aria-selected="false">
                                    <h3 class="title roboto mb-0">
                                        Resources
                                    </h3>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-three-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-three" type="button" role="tab" aria-controls="pills-three"
                                    aria-selected="false">
                                    <h3 class="title roboto mb-0">
                                        Daily life Applications
                                    </h3>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-four-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-four" type="button" role="tab" aria-controls="pills-four"
                                    aria-selected="false">
                                    <h3 class="title roboto mb-0">
                                        Benefits
                                    </h3>
                                </button>
                            </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-one" role="tabpanel"
                                aria-labelledby="pills-one-tab" tabindex="0">
                                <div class="instructorCard">
                                    1
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-two" role="tabpanel" aria-labelledby="pills-two-tab"
                                tabindex="0">
                                <div class="instructorCard">
                                    2
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab"
                                tabindex="0">
                                <div class="instructorCard">
                                    3
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-four" role="tabpanel" aria-labelledby="pills-four-tab"
                                tabindex="0">
                                <div class="instructorCard">
                                    4
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CourseCurriculum