import React from 'react'
import Form from '../components/Form'
import FAQ from '../components/FAQ'
import Newnavbar from '../components/Newnavbar'
import Footer from '../components/Footer'
import NewHeroSection from '../components/NewHeroSection'
import LifeChallenge from '../components/LifeChallenge'
import BannerImg from "../assets/solution-banner.webp"
import CoursesList from '../components/CoursesList'

import BannerMobileImg from "../assets/solution-we-provide-page-for-mobile.webp"
import BannerTabImg from "../assets/solution-we-provide-page-for-tab.webp"



const Solution = () => {
  return (
    <div>
        <Newnavbar/>
        <NewHeroSection
        customClassName="solutionPage"
        bannerImg={BannerImg}
        bannerTabImg= {BannerTabImg}
        bannerMobileImg= {BannerMobileImg}
        title="Turn All Your Mental Struggles into Strength"
        subtitle="Decoding Mental Health Issues Using The Silva Method Mind Control Techniques"
        description=""
        buttonText="Get Started"
        />
        <LifeChallenge/>
        <CoursesList/>
        <Form/>
        <FAQ/>
        <Footer/>
    </div>
  )
}

export default Solution