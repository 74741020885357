import React from 'react'
import "../../components/HeroSection_CoursesStyle.css";
// import HeaderBGImg from "../../../assets/course-header.webp"
import CardThumbImgDef from "../../assets/card-thumb-img.webp"
import CheckDarkImg from "../../assets/check-dark.svg"
// import HeaderMobileBGImg from "../../../assets/courses-header-for-mobile-tab.webp"
// import HeaderTabBGImg from "../../../assets/courses-header-for-mobile-tab.webp"

const SlsHeroSection = ({course_title,course_description,btnCta,BtnClick,CardThumbImg,discountPrice,originalPrice,discount_percentage,star,ratingCount,perksData,card}) => {


  return (
    <div>
        <section className="heroSection lightBg position-relative coursePage sls">
            {/* <img src={HeaderBGImg} className="d-block w-100" alt="#" loading="lazy" /> */}
             
            <img src={"/assets/assets/img/sls/sls-15.webp"} class="d-md-none d-block w-100" alt="#"
                loading="lazy" style={{height:"700px",objectFit:"cover"}}/>
            <img src={"/assets/assets/img/sls/sls-15.webp"} class="d-none d-md-block d-lg-none w-100"
                alt="#" loading="lazy" style={{height:"700px",objectFit:"cover"}}/>
            <img src={"/assets/assets/img/sls/sls-15.webp"}class="d-none d-lg-block w-100 bannerLg" alt="#" loading="lazy" style={{height:"1000px",objectFit:"cover"}}/>
            
            <div className="sls carousel-caption">
                <div className="container">
                    <div className="d-flex justify-content-between totalContArea">
                        <div className="heroCont">
                            <h1 className="h1 fw700 poppins">
                                {course_title}
                            </h1>
                            <p className="desc fw300 roboto">
                               {course_description}
                            </p>
                            <button className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape border-0 mb-5" onClick={BtnClick}>
                                {btnCta}
                            </button>
                            {card}
                        </div>
                       
                        <div className="rightCard">
                            <div className="thumbImg">
                                <img src={CardThumbImg || CardThumbImgDef} alt="#" loading="lazy" className="w-100 h100" />
                            </div>
                            <p className="fw500 roboto textDark shapeRect lightTrape mt-3 mb-2">
                                Course Price
                            </p>
                            <div className="d-flex align-items-center flex-wrap column-gap-4 row-gap-1 mb-4 mb-md-2">
                                <p className="p1 fw900 roboto textDark d-flex align-items-center justify-content-center mb-0">
                                    <span className="me-1">₹</span>
                                    <span className="roboto">{discountPrice} {course_title==="Silva Life System"?"(INR)":""}</span>
                                </p>
                                {course_title==="Silva Life System" && <p className="p1 fw900 roboto textDark d-flex align-items-center justify-content-center mb-0">
                                    <span className="me-1">₹</span>
                                    <span className="roboto">{"7500"} {course_title==="Silva Life System"?"(BDT)":""}</span>
                                </p>}
                                <p className="p1 fw900 oldPrice textLight mb-0">
                                    <span className="me-1">₹</span>
                                    <span className="roboto">{originalPrice}</span>
                                </p>
                                <p className="fw600 roboto textDark mb-0">
                                    You will get {discount_percentage}% Off
                                </p>
                            </div>
                            <div className="d-flex align-items-center flex-wrap mb-5">
                                <p className="title fw700 poppins textDark mb-0">{star}</p>
                                <div className="d-flex align-items-center ratingStarIcon ms-2 me-4">
                                    <span className="material-symbols-outlined">
                                        star
                                    </span>
                                    <span className="material-symbols-outlined">
                                        star
                                    </span>
                                    <span className="material-symbols-outlined">
                                        star
                                    </span>
                                    <span className="material-symbols-outlined">
                                        star
                                    </span>
                                    <span className="material-symbols-outlined">
                                        star_half
                                    </span>
                                </div>
                                <p className="fw600 roboto textDark mb-0">
                                    ({ratingCount} ratings)
                                </p>
                            </div>
                            <h3 className="title fw700 poppins textDark mb-3">
                                Unlock Unlimited Perks
                            </h3>
                            <ul className="resetUl unlockList">
                                {
                                    perksData && perksData.map((perks,i)=>
                                <li className="d-flex align-items-center gap-2 mb-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy" />
                                    <p className="textDark roboto mb-0">{perks}</p>
                                </li>
                                    
                                    )
                                }
                                {/* More list items */}
                                {/* <li class="d-flex align-items-center gap-2 mb-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy"/>
                                    <p class="textDark roboto mb-0">Powerful Silva Method Meditation Techniques</p>
                                </li>
                                <li class="d-flex align-items-center gap-2 mb-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy"/>
                                    <p class="textDark roboto mb-0">Mind Control Tools and Training</p>
                                </li>
                                <li class="d-flex align-items-center gap-2 mb-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy"/>
                                    <p class="textDark roboto mb-0">Access to a Global Community</p>
                                </li>
                                <li class="d-flex align-items-center gap-2 mb-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy"/>
                                    <p class="textDark roboto mb-0">Doubt Clearing and Healing Session</p>
                                </li>
                                <li class="d-flex align-items-center gap-2 mb-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy"/>
                                    <p class="textDark roboto mb-0">Weekly Meetups for Silva Graduates</p>
                                </li>
                                <li class="d-flex align-items-center gap-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy"/>
                                    <p class="textDark roboto mb-0">Instant Money Back Guarantee</p>
                                </li> */}
                            </ul>
                            {/* <div className="eventDate">
                                <div className="d-flex align-items-center justify-content-between">
                                    <p className="fw700 poppins textDark mb-0">
                                        Event <span className="gradientText">Dates</span>
                                    </p>
                                    <p className="fw500 roboto primaryWhite shapeRect gradientBg mb-0">
                                        2024
                                    </p>
                                </div>
                                <div className="dateBoxes">
                                <div class="dateBox">
                                        <div class="h2 fw600 roboto textDark innerBox">04</div>
                                        <p class="title poppins textDark mb-0">May</p>
                                    </div>
                                    <div class="dateBox">
                                        <div class="h2 fw600 roboto textDark innerBox">05</div>
                                        <p class="title poppins textDark mb-0">May</p>
                                    </div>
                                    <div class="dateBox">
                                        <div class="h2 fw600 roboto textDark innerBox">11</div>
                                        <p class="title poppins textDark mb-0">May</p>
                                    </div>
                                    <div class="dateBox">
                                        <div class="h2 fw600 roboto textDark innerBox">12</div>
                                        <p class="title poppins textDark mb-0">May</p>
                                    </div>
                                    <div class="dateBox">
                                        <div class="h2 fw600 roboto textDark innerBox">12</div>
                                        <p class="title poppins textDark mb-0">May</p>
                                    </div>
                                    <div class="dateBox">
                                        <div class="h2 fw600 roboto textDark innerBox">12</div>
                                        <p class="title poppins textDark mb-0">May</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="text-center mt-5">
                                {/* <button type="button" onClick={paymentHandler} className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape btnPulse">
                                    enroll now
                                </button> */}
                                <div class="text-center">
                                {/* <button type="button" onClick={paymentHandler} class="text-capitalize fw700 primaryBlack btnPulse">
                                    enroll now
                                </button> */}
                                <button type="button"  class="text-capitalize fw700 primaryBlack btnPulse">
                                    Coming Soon
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default SlsHeroSection