import React from 'react'
import "./SubconsciousMindStyle.css";
import FooterBGImg from "../assets/footer-bg.webp"
// import SubconsciousVideo from "../assets/subconscious-mind.mp4"
import { Link } from 'react-scroll';
import ReactPlayer from 'react-player';
import {handleScroll} from '../utils/animPulse'
const SubconsciousVideo ="https://vz-6873ed28-3a2.b-cdn.net/ced8be68-e23f-4581-a448-632eb2d806d4/playlist.m3u8"



const SubconsciousMind = () => {
  return (
    <div>
        <section className="subconsciousSection lightBg ptop pbtm">
      <div id="clouds">
        <div className="cloud x1"></div>
        <div className="cloud x2"></div>
        <div className="cloud x3"></div>
        <div className="cloud x4"></div>
        <div className="cloud x5"></div>
      </div>
      <div className="container">
        <div className="row align-items-center position-relative row-gap-4">
          <div className="col-lg-6 col-xl-5 text-center text-lg-start">
            <h2 className="h2 fw700 poppins textDark mb-3 mb-lg-5">
              How to Master <span className="gradientText">The Subconscious Mind?</span>
            </h2>
            <p className="textDark roboto mb-3 mb-lg-5">
              When you encounter challenging situations, it's common to feel stressed or panicked, causing
              your brain to operate at higher frequencies, typically above 14 Hz. The Silva Method offers
              you a transformative approach to navigating such moments. It guides you to slow down your
              brain frequencies and enter the Alpha state of mind. This Alpha state or the Human
              Intelligence Level, is a state of deep relaxation and heightened awareness where you can
              access your subconscious mind more effectively. The Silva Method helps you access your
              subconscious while staying alert in the Alpha state. This enhances problem-solving and
              decision-making by providing clarity and focus.
            </p>
            <Link to="masterClassForm" smooth={true} duration={500}>
            <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0 shadowLight"  onClick={()=>handleScroll()}>
              Get Access Now
            </button>
            </Link>
          </div>
          <div className="col-lg-6 col-xl-7">
            <div className="videoArea ms-auto">
            {/* <video autoPlay muted loop width="100%" height="100%">
            <source src={SubconsciousVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
          <ReactPlayer
                  url={SubconsciousVideo}
                  // controls
                  width="100%"
                  height="100%"
                  playing
                  muted
                  loop // Add the loop prop to enable looping
                  // config={{
                  //   hlsOptions: {
                  //     // You can add additional HLS options here if needed
                  //   }
                  // }}
                />
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default SubconsciousMind