import React , { useState }from 'react'
import "./SendUsMessageStyle.css";
import ManImg from "../assets/man-1.webp"
import axios from 'axios';

const SendUsMessage = () => {

    const [formData, setFormData] = useState({
        fname: '',
        email: '',
        mobile: '',
        msg: ''
    
    
        
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post('https://dedicated-nurture-a182b31812.strapiapp.com/api/contact-page-forms', {
            data: {
              Name: formData.fname,
              Mobile: formData.mobile,
              Email: formData.email,
              Query: formData.msg
            }
          },
          {
            headers: {
              Authorization: 'Bearer 0943d95f06e6151e04e04724e9871938f025a3e1b888414e39150ba23424c9496c2445c38e159be6b943f8ee376d8cc301df2ceb033e0d2c00eca18df8c9053e2433b7e3cfdf837808702b5c7477d3c0a0b54a92ce1c46f16e217db1fbcc4001ad323dd9207a89729ddad39eff85d4fc7ee07489885a300c1c7fe9f18e9d0c7f'
              // Replace 'YOUR_API_TOKEN' with your actual API token
            }
          }
        );
          console.log('Response:', response.data);
          // Reset form fields after successful submission
          setFormData({
            fname: '',
            
            email: '',
            mobile: '',
            
            msg: ''
          });
        } catch (error) {
          console.error('Error:', error);
        }
      };



  return (
    <div>
        <section className="talkInstructorSection ptop lightBg blankPaddingBtm">
            <div id="clouds">
                <div className="cloud x1"></div>
                <div className="cloud x2"></div>
                <div className="cloud x3"></div>
                <div className="cloud x4"></div>
                <div className="cloud x5"></div>
            </div>
            <div className="container">
                <div className="contArea text-center">
                    <h2 className="h2 fw700 poppins textDark">
                        Send us a <span className="gradientText">Message</span>
                    </h2>
                    <p className="fw600 poppins textDark mb-5">
                        Begin your journey of empowering the mind. Get in touch with us by filling the form.
                    </p>
                    <div className="row">
                        <div className="col-md-7 col-lg-6">
                            <div className="leftForm">
                                <p className="mdFont fw800 poppins textDark mb-4">
                                    Submit the form
                                </p>
                                <form onSubmit={handleSubmit}>
                                    <div className="row gy-4">
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" name="fname" value={formData.fname} onChange={handleChange} placeholder="Full Name" required />
                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="Mobile Number" required />
                                        </div>
                                        <div className="col-md-12">
                                            <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required />
                                        </div>
                                        <div className="col-md-12">
                                            <textarea className="form-control" name="msg" value={formData.msg} onChange={handleChange} id="inputMsg" placeholder="Type your Query" style={{ height: '120px' }} required></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-5">
                                        submit
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-6">
                            <div className="rightImg">
                                <img src={ManImg} alt="#" loading="lazy" className="w100 h100" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default SendUsMessage