import React from 'react'
import "./InstructorListStyle.css";
import DebasishImg from "../assets/Debasish-Chatterjee.webp"
import DebayanImg from "../assets/Debayan-Sen.webp"
import SoumyajeetImg from "../assets/Soumyajeet-Bandyopadhay.webp"
import DebalinaImg from "../assets/Debalina-Chatterjee.webp"
import PhoneDarkLogoImg from "../assets/phn-dark.svg"
import MailDarkLogoImg from "../assets/mail-dark.svg"
import FacebookLogoImg from "../assets/facebook-official.svg"
import TwitterLogoImg from "../assets/twitter-official.svg"
import InstagramLogoImg from "../assets/instagram-official.svg"
import LinkedInLogoImg from "../assets/linkedin-official.svg"
import YoutubeInLogoImg from "../assets/youtube-official.svg"
import { Link } from 'react-router-dom';

const InstructorList = () => {
  return (
    <div>
      <section className="instructorsSection lightBg ptop pbtm position-relative">
        <div id="clouds">
          <div className="cloud x1"></div>
          <div className="cloud x2"></div>
          <div className="cloud x3"></div>
          <div className="cloud x4"></div>
          <div className="cloud x5"></div>
          <div className="cloud x6"></div>
          <div className="cloud x7"></div>
          <div className="cloud x8"></div>
          <div className="cloud x9"></div>
          <div className="cloud x10"></div>
          <div className="cloud x11"></div>
          <div className="cloud x12"></div>
        </div>
        <div className="container">
          <div className="headingArea text-center position-relative">
            <p className="title fw600 poppins textDark mb-2">
              Our Specialties
            </p>
            <h2 className="h2 fw700 poppins textDark text-capitalize">
              why choose <span className="gradientText">silva method</span>
            </h2>
          </div>
          <div className="instructorCard">
            <div className="cardItem">
              <div className="leftSide">
                <img src={DebasishImg} alt="#" loading="lazy" className="w100 h100" />
              </div>
              <div className="rightSide">
                <h3 className="fw700 poppins mb-2">
                  Debasish Chatterjee
                </h3>
                <p className="fw400 roboto shapeRect lightTrape mb-4">
                  Silva Instructor
                </p>
                <p className="desc fw400 poppins mb-4">
                  Debasish Chatterjee, with an illustrious career spanning nearly 40 years as a senior accountant, offers a remarkable fusion of financial expertise and spiritual wisdom in his role as a Certified Silva Method Instructor. <span className="hideText">But Debasish’s journey goes even deeper, as he has dedicated over five decades of his life to spiritual practice, making</span>
                </p>
                <button type="button" data-bs-toggle="modal" data-bs-target="#instructorModal1" className="btnMain fw700 gradientBg primaryWhite text-capitalize shapeTrape">
                  know more
                </button>
              </div>
            </div>
            <div className="cardItem">
              <div className="leftSide">
                <img src={DebayanImg} alt="#" loading="lazy" className="w100 h100" />
              </div>
              <div className="rightSide">
                <h3 className="fw700 poppins mb-2">
                  Debayan Sen
                </h3>
                <p className="fw400 roboto shapeRect lightTrape mb-3">
                  Silva Instructor
                </p>
                <p className="desc fw400 poppins mb-4">
                  Debayan is an extraordinary Certified Silva Method Instructor who brings a wealth of experience and a multifaceted background to his mission of personal empowerment and transformation. <span className="hideText">Before venturing into the world of Silva Method, he honed his skills and knowledge while working with esteemed multinational corporations like</span>
                </p>
                <button type="button" data-bs-toggle="modal" data-bs-target="#instructorModal2" className="btnMain fw700 gradientBg primaryWhite text-capitalize shapeTrape">
                  know more
                </button>
              </div>
            </div>
            <div className="cardItem">
              <div className="leftSide">
                <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
              </div>
              <div className="rightSide">
                <h3 className="fw700 poppins mb-2">
                  Soumyajeet Bandyopadhay
                </h3>
                <p className="fw400 roboto shapeRect lightTrape mb-3">
                  Silva Instructor
                </p>
                <p className="desc fw400 poppins mb-4">
                  As a Certified Silva Method Instructor, Soumyajeet brings a dynamic and passionate approach to personal empowerment through his training sessions. <span className="hideText">With a background in Software Engineering, Branding & Advertising, he is equipped with a lot of technical expertise. He joined Silva 4 years back and through Silva Method has gained</span>
                </p>
                <button type="button" data-bs-toggle="modal" data-bs-target="#instructorModal3" className="btnMain fw700 gradientBg primaryWhite text-capitalize shapeTrape">
                  know more
                </button>
              </div>
            </div>
            <div className="cardItem">
              <div className="leftSide">
                <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
              </div>
              <div className="rightSide">
                <h3 className="fw700 poppins mb-2">
                  Debalina Chatterjee
                </h3>
                <p className="fw400 roboto shapeRect lightTrape mb-3">
                  Silva Instructor
                </p>
                <p className="desc fw400 poppins mb-4">
                  As a Certified Silva Method Instructor, Debalina brings a dynamic and passionate approach to personal empowerment through her training sessions. <span className="hideText">As a software engineer background in esteemed multinational corporations such as Accenture and Tata Consultancy Services, she is equipped with problem-solving skills and a deep understanding</span>
                </p>
                <button type="button" data-bs-toggle="modal" data-bs-target="#instructorModal4" className="btnMain fw700 gradientBg primaryWhite text-capitalize shapeTrape">
                  know more
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal 1  */}
      <div className="modal fade" id="instructorModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="row contGap">
              <div className="col-md-5 col-lg-6 col-xl-5">
                <div className="modalLeftCont">
                  <div className="imgCont">
                    <img src={DebasishImg} alt="#" className="w100 h100" />
                  </div>
                  <div className="row infoGap">
                    <div className="col-lg-6">
                      <div className="leftInfo">
                        <h3 className="poppins textDark mb-3">Contact Info</h3>
                        <ul className="resetUl">
                          <li className="d-flex align-items-baseline gap-2 mb-2">
                            <div className="infoIcon">
                              <img src={PhoneDarkLogoImg} alt="#" loading="lazy" />
                            </div>
                            <a href="#" className="textDark roboto">+91 6290 945 413</a>
                          </li>
                          <li className="d-flex align-items-baseline gap-2">
                            <div className="infoIcon">
                              <img src={MailDarkLogoImg} alt="#" loading="lazy" />
                            </div>
                            <a href="#" className="textDark roboto">better@silvamethod.in</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="rightInfo">
                        <h3 className="poppins textDark mb-3">Stay Connected</h3>
                        <div className="socialIcons d-flex gap-1">
                          <a href="#">
                            <img src={FacebookLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={TwitterLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={InstagramLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={LinkedInLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={YoutubeInLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-lg-6 col-xl-7">
                <div className="modalRightCont ps-md-4">
                  <h3 className="h2 fw700 poppins textDark mb-2">Debasish Chatterjee</h3>
                  <p className="fw500 roboto textDark shapeRect lightTrape mb-4">Silva Instructor</p>
                  <p className="desc fw400 textDark poppins mb-4">
                    Debasish Chatterjee, with an illustrious career spanning nearly 40 years as a senior accountant, offers a remarkable fusion of financial expertise and spiritual wisdom in his role as a Certified Silva Method Instructor. But Debasish’s journey goes even deeper, as he has dedicated over five decades of his life to spiritual practice, making him a true luminary in this domain.
                  </p>
                  <p className="desc fw400 textDark poppins mb-0">
                    Debasish’s experience in the financial world has honed his analytical skills and an acute understanding of numbers. However, his true passion lies in the realm of spirituality, where he has devoted himself for more than 50 years. Now, he is embarking on a profound journey by intertwining his extensive spiritual insights with the Silva Method, infusing it with a new and transformative dimension.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal 1 ends */}

      {/* Modal 2 */}
      <div className="modal fade" id="instructorModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="row contGap">
              <div className="col-md-5 col-lg-6 col-xl-5">
                <div className="modalLeftCont">
                  <div className="imgCont">
                    <img src={DebayanImg} alt="#" className="w100 h100" />
                  </div>
                  <div className="row infoGap">
                    <div className="col-lg-6">
                      <div className="leftInfo">
                        <h3 className="poppins textDark mb-3">Contact Info</h3>
                        <ul className="resetUl">
                          <li className="d-flex align-items-baseline gap-2 mb-2">
                            <div className="infoIcon">
                              <img src={PhoneDarkLogoImg} alt="#" loading="lazy" />
                            </div>
                            <a href="#" className="textDark roboto">+91 6290 945 413</a>
                          </li>
                          <li className="d-flex align-items-baseline gap-2">
                            <div className="infoIcon">
                              <img src={MailDarkLogoImg} alt="#" loading="lazy" />
                            </div>
                            <a href="#" className="textDark roboto">better@silvamethod.in</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="rightInfo">
                        <h3 className="poppins textDark mb-3">Stay Connected</h3>
                        <div className="socialIcons d-flex gap-1">
                          <a href="#">
                            <img src={FacebookLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={TwitterLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={InstagramLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={LinkedInLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={YoutubeInLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-lg-6 col-xl-7">
                <div className="modalRightCont ps-md-4">
                  <h3 className="h2 fw700 poppins textDark mb-2">Debayan Sen</h3>
                  <p className="fw500 roboto textDark shapeRect lightTrape mb-4">Silva Instructor</p>
                  <p className="desc fw400 textDark poppins mb-4">
                    Debayan is an extraordinary Certified Silva Method Instructor who brings a wealth of experience and a multifaceted background to his mission of personal empowerment and transformation. Before venturing into the world of Silva Method, he honed his skills and knowledge while working with esteemed multinational corporations like Infosys and Flipkart.
                  </p>
                  <p className="desc fw400 textDark poppins mb-0">
                    Having walked the corporate path, Debayan understands the demands and pressures of the modern professional world. He combines his corporate insights with his passion for self-discovery to help individuals tap into their latent potential. As the Co-founder of the forward-thinking IT company, Semicolon ITES, he seamlessly blends entrepreneurial acumen with a deep commitment to personal growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal 2 Ends */}

      {/* Modal 3 */}
      <div className="modal fade" id="instructorModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="row contGap">
              <div className="col-md-5 col-lg-6 col-xl-5">
                <div className="modalLeftCont">
                  <div className="imgCont">
                    <img src={SoumyajeetImg} alt="#" className="w100 h100" />
                  </div>
                  <div className="row infoGap">
                    <div className="col-lg-6">
                      <div className="leftInfo">
                        <h3 className="poppins textDark mb-3">Contact Info</h3>
                        <ul className="resetUl">
                          <li className="d-flex align-items-baseline gap-2 mb-2">
                            <div className="infoIcon">
                              <img src={PhoneDarkLogoImg} alt="#" loading="lazy" />
                            </div>
                            <a href="#" className="textDark roboto">+91 6290 945 413</a>
                          </li>
                          <li className="d-flex align-items-baseline gap-2">
                            <div className="infoIcon">
                              <img src={MailDarkLogoImg} alt="#" loading="lazy" />
                            </div>
                            <a href="#" className="textDark roboto">better@silvamethod.in</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="rightInfo">
                        <h3 className="poppins textDark mb-3">Stay Connected</h3>
                        <div className="socialIcons d-flex gap-1" >
                          <a href="#">
                            <img src={FacebookLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={TwitterLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={InstagramLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={LinkedInLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={YoutubeInLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-lg-6 col-xl-7">
                <div className="modalRightCont ps-md-4">
                  <h3 className="h2 fw700 poppins textDark mb-2">Soumyajeet Bandyopadhay</h3>
                  <p className="fw500 roboto textDark shapeRect lightTrape mb-4">Silva Instructor</p>
                  <p className="desc fw400 textDark poppins mb-4">
                    As a Certified Silva Method Instructor, Soumyajeet brings a dynamic and passionate approach to personal empowerment through his training sessions. With a background in Software Engineering, Branding & Advertising, he is equipped with a lot of technical expertise. He joined Silva 4 years back and through Silva Method has gained amazing problem-solving skills and a deep understanding of how the Human Mind works, which he seamlessly integrates into his teachings.
                  </p>
                  <p className="desc fw400 textDark poppins mb-0">
                    Soumyajeet is dedicated to helping individuals take control of their destinies and unlock their full potential. Through the Silva Method, he provides you with a toolkit of powerful techniques that will enable you to shape the reality you truly desire.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal 3 ends */}

      {/* Modal 4 */}
      <div className="modal fade" id="instructorModal4" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="row contGap">
              <div className="col-md-5 col-lg-6 col-xl-5">
                <div className="modalLeftCont">
                  <div className="imgCont">
                    <img src={DebalinaImg} alt="#" className="w100 h100" />
                  </div>
                  <div className="row infoGap">
                    <div className="col-lg-6">
                      <div className="leftInfo">
                        <h3 className="poppins textDark mb-3">Contact Info</h3>
                        <ul className="resetUl">
                          <li className="d-flex align-items-baseline gap-2 mb-2">
                            <div className="infoIcon">
                              <img src={PhoneDarkLogoImg} alt="#" loading="lazy" />
                            </div>
                            <a href="#" className="textDark roboto">+91 6290 945 413</a>
                          </li>
                          <li className="d-flex align-items-baseline gap-2">
                            <div className="infoIcon">
                              <img src={MailDarkLogoImg} alt="#" loading="lazy" />
                            </div>
                            <a href="#" className="textDark roboto">better@silvamethod.in</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="rightInfo">
                        <h3 className="poppins textDark mb-3">Stay Connected</h3>
                        <div className="socialIcons d-flex gap-1">
                          <a href="#">
                            <img src={FacebookLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={TwitterLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={InstagramLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={LinkedInLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                          <a href="#">
                            <img src={YoutubeInLogoImg} alt="#" loading="lazy" className="w100 h100" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-lg-6 col-xl-7">
                <div className="modalRightCont ps-md-4">
                  <h3 className="h2 fw700 poppins textDark mb-2">Debalina Chatterjee</h3>
                  <p className="fw500 roboto textDark shapeRect lightTrape mb-4">Silva Instructor</p>
                  <p className="desc fw400 textDark poppins mb-4">
                    As a Certified Silva Method Instructor, Debalina brings a dynamic and passionate approach to personal empowerment through her training sessions. With a background in esteemed multinational corporations such as Accenture and Tata Consultancy Services, she is equipped with problem-solving skills and a deep understanding of how the mind works, which she constantly integrates into her teachings. Debalina is dedicated to helping individuals take control of their destinies and unlock their full potential. Through the Silva Method, she provides you with a toolkit of powerful techniques that will enable you to shape the reality you truly desire.
                  </p>
                  <p className="desc fw400 textDark poppins mb-0">
                    You can find Debalina in The Podcast series "The Silva Talk Show" hosted on the Silva Method Official YouTube channel, where she discusses amazing topics with other instructors from all over the world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal 4 ends */}

    </div>
  )
}

export default InstructorList