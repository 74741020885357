import React from 'react'
import Newnavbar from '../../components/Newnavbar'
import NewHeroSection from '../../components/NewHeroSection'
import Footer from '../../components/Footer'

import BannerMobileImg from "../../assets/instructorpage-for-mobile.webp"
import BannerTabImg from "../../assets/instructorpage-for-tab.webp"
import MasterTech from '../../components/MasterTech'
import Newpricecard from '../../components/Newpricecard'
import CourseCurriculum from '../../components/CourseCurriculum'

const SLSproblemFixer = () => {
    const cardItem = { status: true, component: <Newpricecard /> }
    return (
        <>
            <Newnavbar />
            <NewHeroSection
                customClassName="slsFixerPage"
                titleStyle="titleStyle"
                subtitleStyle="subtitleStyle"
                bannerImg="/assets/images(rahul)/sls-banner.webp"
                bannerTabImg={BannerTabImg}
                bannerMobileImg={BannerMobileImg}
                title="Silva Life System"
                subtitle="When in trouble, go to your LEVEL !"
                description="The Silva Method Problem Fixer"
                buttonText="Enroll Now"
                cardPrice={cardItem}
            />
            <MasterTech />
            <CourseCurriculum />
            {/* <Footer /> */}
        </>
    )
}

export default SLSproblemFixer