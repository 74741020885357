import React from 'react'
import "./ShouldJoinMasterclassStyle.css";
import CheckImg from "../assets/check-bg-dark.svg"
import { Link } from 'react-scroll';
import ReactPlayer from 'react-player';
import {handleScroll} from '../utils/animPulse'
import BannerImg from "../assets/Banner.webp"
const InstructorVideo ="https://vz-6873ed28-3a2.b-cdn.net/de0aaca8-642a-4b9d-a356-faf0b9ac4f8c/playlist.m3u8"


const ShouldJoinMasterclass = () => {
  return (
    <div>
        <section className="masterClass lightBg ptop pbtm">
      <div className="container">
        <div className="masterClassCont">
          <div className="row align-items-center row-gap-4">
            <div className="col-lg-6 col-xl-7">
              <div className="videoArea firstvideoArea">
                {/* <video autoPlay muted loop width="100%" height="100%">
                  <source src="https://www.youtube.com/embed/zkWCbJxn7as?si=b61NNIjj-U6pWIbL" />
                </video> */}
                {/* <iframe width="100%" height="100%" src="https://www.youtube.com/embed/zkWCbJxn7as?si=b61NNIjj-U6pWIbL" title="YouTube video player" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                 <ReactPlayer className="player"
                      light= {BannerImg}
                      controls
                      url={InstructorVideo}
                      width="100%"
                      
                    />
              </div>
            </div>
            <div className="col-lg-6 col-xl-5">
              <div className="rightCont">
                <h2 className="h2 fw700 poppins textDark mb-4">
                  Why Should Join This <span className="gradientText">Masterclass?</span>
                </h2>
                <ul className="resetUl">
                <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Take control of your mind</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0"><span className="fw800">As a bonus,</span> get three free guided meditation</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Learn to conquer your life challenges</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Deeply relax your mind and body</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Slow down brain frequencies</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Increase self-confidence</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Enhance self-control</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Reprogram the subconscious mind</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Release pain and improve health</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Get rid of bad habits</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Manage relationship Issues</p>
                  </li>
                </ul>
                <Link to="masterClassForm" smooth={true} duration={500}>
                <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0 shadowLight" onClick={()=>handleScroll()}>
                  enroll now
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default ShouldJoinMasterclass