import React from 'react'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import Footer from '../components/Footer'
import WhyChoose from '../components/WhyChoose'
import UpcomingEvents from '../components/UpcomingEvents'
import DoForYou from '../components/DoForYou'
import AboutUs from '../components/AboutUs'
import Practice from '../components/Practice'
import Benefits from '../components/Benefits'
import TalkInstructor from '../components/TalkInstructor'
import CoursesList from '../components/CoursesList'
import Instructors from '../components/Instructors'
import SliderBand from '../components/SliderBand'
import TypeSetting from '../components/TypeSetting'
import Testimonial from '../components/Testimonial'
import Form from '../components/Form'
import FAQ from '../components/FAQ'
import Newnavbar from '../components/Newnavbar'
// import Featured from '../components/Featured'
import VideoTestimonial from '../components/VideoTestimonial'


const Home = () => {
  return (
    <div>
      {/* <Navbar/> */}
      <Newnavbar/>
      <HeroSection/>
      <WhyChoose/>
      <UpcomingEvents/>
      <DoForYou/>
      <AboutUs/>
      <Practice/>
      <Benefits/>
      <TalkInstructor/>
      <CoursesList/>
      <Instructors/>
      <SliderBand/>
      {/* <Featured/> */}
      <TypeSetting/>
      {/* <Testimonial/> */}
      <VideoTestimonial/>
      <Form/>
      <FAQ/>
      <Footer/>
    </div>
  )
}

export default Home