import React from 'react'
import "./UpcomingEventsStyle.css";
import BenefitsImage from "../assets/benefit-banner.webp"




const Benefits = () => {
  return (
    <div>
        <section className="benefitBannerSection lightBg">
      <img src={BenefitsImage} alt="#" loading="lazy" className="w100 h100" />
    </section>
    </div>
  )
}

export default Benefits