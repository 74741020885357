import React from 'react'
import "../../components/CourseScheduleStyle.css";
import DebayanImg from "../../assets/Debayan-Sen.webp"
import ZoomImg from "../../assets/zoom.webp"
import SoumyajeetImg from "../../assets/Soumyajeet-Bandyopadhay.webp"
import DebalinaImg from "../../assets/Debalina-Chatterjee.webp"
import DebasishImg from "../../assets/Debasish-Chatterjee.webp"



const SlsCourseSchedule = () => {
  return (
    <div>
        <section className="courseScheduleSection">  
            <div className="container">

                <div className="text-center mb-5">
                    <h2 className="h2 fw700 poppins textDark text-center mb-2">
                        Course <span className="gradientText">Schedule</span>
                    </h2>
                    <p className="title fw600 poppins textDark">
                        Here is the detailed schedule of our program
                    </p>
                </div>

                <div className="tabsCont text-center">

                    <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist" style={{maxWidth:"308px"}}>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-one-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one"
                                aria-selected="true">
                                <div className="tabBtn d-flex gap-3 align-items-center">
                                    <div className="rightSide">
                                        <h3 className="title fw600 roboto textDark mb-0">
                                            Day
                                        </h3>
                                        {/* <p className="p1 textDark roboto mb-0">2 days</p> */}
                                    </div>
                                    <div className="leftSide roboto fw700 textDark">1</div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-two-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-two" type="button" role="tab" aria-controls="pills-two"
                                aria-selected="false">
                                <div className="tabBtn d-flex gap-3 align-items-center">
                                    <div className="rightSide text-start">
                                        <h3 className="title fw600 roboto textDark mb-0">
                                            Day
                                        </h3>
                                        {/* <p className="p1 textDark roboto mb-0">2 days</p> */}
                                    </div>
                                    <div className="leftSide roboto fw700 textDark">2</div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-three-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-three" type="button" role="tab" aria-controls="pills-three"
                                aria-selected="false">
                                <div className="tabBtn d-flex gap-3 align-items-center">
                                    <div className="rightSide text-start">
                                        <h3 className="title fw600 roboto textDark mb-0">
                                            Day
                                        </h3>
                                        {/* <p className="p1 textDark roboto mb-0">2 days</p> */}
                                    </div>
                                    <div className="leftSide roboto fw700 textDark">3</div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-four-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-four" type="button" role="tab" aria-controls="pills-four"
                                aria-selected="false">
                                <div className="tabBtn d-flex gap-3 align-items-center">
                                    <div className="rightSide text-start">
                                        <h3 className="title fw600 roboto textDark mb-0">
                                            Day
                                        </h3>
                                        {/* <p className="p1 textDark roboto mb-0">2 days</p> */}
                                    </div>
                                    <div className="leftSide roboto fw700 textDark">4</div>
                                </div>
                            </button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-three-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-three" type="button" role="tab" aria-controls="pills-three"
                                aria-selected="false">
                                <div className="tabBtn d-flex gap-3">
                                    <div className="leftSide roboto fw700 textDark">3</div>
                                    <div className="rightSide text-start">
                                        <h3 className="title fw600 roboto textDark mb-0">
                                            Weekend
                                        </h3>
                                        <p className="p1 textDark roboto mb-0">2 days</p>
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-four-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-four" type="button" role="tab" aria-controls="pills-four"
                                aria-selected="false">
                                <div className="tabBtn d-flex gap-3">
                                    <div className="leftSide roboto fw700 textDark">4</div>
                                    <div className="rightSide text-start">
                                        <h3 className="title fw600 roboto textDark mb-0">
                                            Weekend
                                        </h3>
                                        <p className="p1 textDark roboto mb-0">2 days</p>
                                    </div>
                                </div>
                            </button>
                        </li> */}
                    </ul>

                    <div className="tab-content" id="pills-tabContent">
                        {/* Content for different tabs goes here */}
                        <div className="tab-pane fade show active" id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab" tabIndex="0">
                            <div className="instructorCard">
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebayanImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Intro and short relaxation
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debayan Sen
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">
                                        Opening Event
                                        </p>
                                        <p className="title fw600 textDark roboto">
                                        9:00 am - 10:30 am
                                        </p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">
                                        Online class via
                                        </p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Long relaxation
                                    <p className="mt-1">(explaination and exercise)</p>
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Soumyajeet Bandyopadhay
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">10:45 am - 01:00 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">Sleep control, awake control and alarm clock technique</h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">02:15 pm - 4:15 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Dream control and headache control
                                    {/* <p className="mt-1">(explaination and exercise)</p> */}
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">4:30 pm - 06:00 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Review of whole day , practice guideline, homework
                                    {/* <p className="mt-1">(explaination and exercise)</p> */}
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">6:00 pm - 06:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Question and answer session
                                    {/* <p className="mt-1">(explaination and exercise)</p> */}
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">6:30 pm - 07:00 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-two" role="tabpanel" aria-labelledby="pills-two-tab" tabIndex="0">
                            <div className="instructorCard">
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebayanImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Homework feedback
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debayan Sen
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">
                                        Opening Event
                                        </p>
                                        <p className="title fw600 textDark roboto">
                                        9:00 am - 09:30 am
                                        </p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">
                                        Online class via
                                        </p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Review day one
                                    {/* <p className="mt-1">(explaination and exercise)</p> */}
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Soumyajeet Bandyopadhay
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">09:30 am - 09:40 am</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Mental screen and memory pegs explain and exercise
                                    {/* <p className="mt-1">(explaination and exercise)</p> */}
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Soumyajeet Bandyopadhay
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">9:40 am - 10:40 am</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Three fingers technique(explain and exercise)
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">11:00 am - 12:00 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Mirror of the mind (explain and exercise)
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">12:10 pm - 01:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Hand levitation and glove anesthesia
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">02:30 pm - 03:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Glass of water and habit control
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">03:45 pm - 05:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Review of last two days
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">05:30 pm - 06:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Question and answer session
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">06:30 pm - 07:00 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                            </div>
                        </div>
                        {/* <div className="tab-pane fade" id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab" tabIndex="0">
                            <div className="instructorCard">
                            <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                <img src={DebayanImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                <h3 className="fw700 poppins textDark mb-2">Intro and short relaxation</h3>
                                <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debayan Sen
                                </p>
                                <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                    <p className="desc textDark roboto mb-1">Opening Event</p>
                                    <p className="title fw600 textDark roboto">9:00 am - 10:30 am</p>
                                    </div>
                                    <div className="rightCont">
                                    <p className="desc textDark roboto mb-2">Online class via</p>
                                    <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                <h3 className="fw700 poppins textDark mb-2">Long relaxation</h3>
                                <p className="mt-1">(explaination and exercise)</p>
                                <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Soumyajeet Bandyopadhay
                                </p>
                                <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                    <p className="desc textDark roboto mb-1">Opening Event</p>
                                    <p className="title fw600 textDark roboto">10:30 am - 11:30 am</p>
                                    </div>
                                    <div className="rightCont">
                                    <p className="desc textDark roboto mb-2">Online class via</p>
                                    <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                <h3 className="fw700 poppins textDark mb-2">Question and answer session</h3>
                                <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                </p>
                                <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                    <p className="desc textDark roboto mb-1">Opening Event</p>
                                    <p className="title fw600 textDark roboto">11:30 am - 12:30 am</p>
                                    </div>
                                    <div className="rightCont">
                                    <p className="desc textDark roboto mb-2">Online class via</p>
                                    <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                <img src={DebasishImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                <h3 className="fw700 poppins textDark mb-2">Long relaxation</h3>
                                <p className="mt-1">(explaination and exercise)</p>
                                <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debasish Chatterjee
                                </p>
                                <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                    <p className="desc textDark roboto mb-1">Opening Event</p>
                                    <p className="title fw600 textDark roboto">9:30 am - 10:30 am</p>
                                    </div>
                                    <div className="rightCont">
                                    <p className="desc textDark roboto mb-2">Online class via</p>
                                    <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-four" role="tabpanel" aria-labelledby="pills-four-tab" tabIndex="0">
                            <div className="instructorCard">
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebayanImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">Intro and short relaxation</h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debayan Sen
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">9:00 am - 10:30 am</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">Long relaxation</h3>
                                    <p className="mt-1">(explaination and exercise)</p>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Soumyajeet Bandyopadhay
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">10:30 am - 11:30 am</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebasishImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">Long relaxation</h3>
                                    <p className="mt-1">(explaination and exercise)</p>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debasish Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">9:30 am - 10:30 am</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">Question and answer session</h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">11:30 am - 12:30 am</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="tab-pane fade" id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab" tabIndex="0">
                            <div className="instructorCard">
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebayanImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Homework feedback
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debayan Sen
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">
                                        Opening Event
                                        </p>
                                        <p className="title fw600 textDark roboto">
                                        9:00 am - 09:30 am
                                        </p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">
                                        Online class via
                                        </p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Review day one
                                    {/* <p className="mt-1">(explaination and exercise)</p> */}
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Soumyajeet Bandyopadhay
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">09:30 am - 09:40 am</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Mental screen and memory pegs explain and exercise
                                    {/* <p className="mt-1">(explaination and exercise)</p> */}
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Soumyajeet Bandyopadhay
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">9:40 am - 10:40 am</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                {/* <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Three fingers technique(explain and exercise)
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">11:00 am - 12:00 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Mirror of the mind (explain and exercise)
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">12:10 pm - 01:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Hand levitation and glove anesthesia
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">02:30 pm - 03:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Glass of water and habit control
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">03:45 pm - 05:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Review of last two days
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">05:30 pm - 06:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Question and answer session
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">06:30 pm - 07:00 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div> */}

                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-four" role="tabpanel" aria-labelledby="pills-four-tab" tabIndex="0">
                            <div className="instructorCard">
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebayanImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Homework feedback
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debayan Sen
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">
                                        Opening Event
                                        </p>
                                        <p className="title fw600 textDark roboto">
                                        9:00 am - 09:30 am
                                        </p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">
                                        Online class via
                                        </p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Review day one
                                    {/* <p className="mt-1">(explaination and exercise)</p> */}
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Soumyajeet Bandyopadhay
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">09:30 am - 09:40 am</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Mental screen and memory pegs explain and exercise
                                    {/* <p className="mt-1">(explaination and exercise)</p> */}
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Soumyajeet Bandyopadhay
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">9:40 am - 10:40 am</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Three fingers technique(explain and exercise)
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">11:00 am - 12:00 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Mirror of the mind (explain and exercise)
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">12:10 pm - 01:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
{/* 
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Hand levitation and glove anesthesia
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">02:30 pm - 03:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Glass of water and habit control
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">03:45 pm - 05:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Review of last two days
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">05:30 pm - 06:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Question and answer session
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">06:30 pm - 07:00 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div> */}

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    </div>
  )
}

export default SlsCourseSchedule