import React from 'react';
import "./HeroSectionStyle.css";
import MobileBannerImg1 from "../assets/homepage-banner-for-mobile-01.webp"
import MobileBannerImg2 from "../assets/homepage-banner-for-mobile-02.webp"
import MobileBannerImg3 from "../assets/homepage-banner-for-mobile-03.webp"
import TabBannerImg1 from "../assets/homepage-banner-for-tab-01.webp"
import TabBannerImg2 from "../assets/homepage-banner-for-tab-02.webp"
import TabBannerImg3 from "../assets/homepage-banner-for-tab-03.webp"
import BannerImg1 from "../assets/slider-bg-1.webp"
import BannerImg2 from "../assets/slider-bg-2.webp"
import BannerImg3 from "../assets/slider-bg-3.webp"

const HeroSection = () => {
  return (
    <div>
        {/* <section className="heroSection lightBg">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="indicatorsBtn active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" className="indicatorsBtn" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" className="indicatorsBtn" aria-label="Slide 3"></button>
        </div>

        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={BannerImg} className="d-block w-100" alt="#" loading="lazy" />
            <div className="carousel-caption">
              <div className="container">
                <div className="heroCont">
                  <p className="title poppins p1">
                    Start a Happy Life
                  </p>
                  <h1 className="h1 fw700 poppins">
                    Tap into the Untapped Areas of Your Mind
                  </h1>
                  <p className="desc fw300 roboto">
                    The Silva Method is a proven meditation program and the mind control technique for personal growth, health, intuition, and spiritual well-being.
                  </p>
                  <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                    get started
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src={BannerImg} className="d-block w-100" alt="#" loading="lazy" />
            <div className="carousel-caption">
              <div className="container">
                <div className="heroCont">
                  <p className="title poppins p1">
                    Start a Happy Life
                  </p>
                  <h1 className="h1 fw700 poppins">
                    Tap into the Untapped Areas of Your Mind
                  </h1>
                  <p className="desc fw300 roboto">
                    The Silva Method is a proven meditation program and the mind control technique for personal growth, health, intuition, and spiritual well-being.
                  </p>
                  <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                    get started
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src={BannerImg} className="d-block w-100" alt="#" loading="lazy" />
            <div className="carousel-caption">
              <div className="container">
                <div className="heroCont">
                  <p className="title poppins p1">
                    Start a Happy Life
                  </p>
                  <h1 className="h1 fw700 poppins">
                    Tap into the Untapped Areas of Your Mind
                  </h1>
                  <p className="desc fw300 roboto">
                    The Silva Method is a proven meditation program and the mind control technique for personal growth, health, intuition, and spiritual well-being.
                  </p>
                  <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                    get started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    <section className="heroSection lightBg">
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
            className="indicatorsBtn active" aria-current="true" aria-label="Slide 1">
          </button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
            className="indicatorsBtn" aria-label="Slide 2">
          </button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
            className="indicatorsBtn" aria-label="Slide 3">
          </button>
        </div>

        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={MobileBannerImg1} className="d-md-none d-block w-100"
              alt="Slide 1" loading="lazy" />
            <img src={TabBannerImg1}
              className="d-none d-md-block d-lg-none w-100" alt="Slide 1" loading="lazy" />
            <img src={BannerImg1} className="d-none d-lg-block w-100" alt="Slide 1" loading="lazy" />
            <div className="carousel-caption">
              <div className="container">
                <div className="heroCont">
                  <p className="title poppins p1">
                    Start a Happy Life
                  </p>
                  <h1 className="h1 fw700 poppins">
                    Tap into the Untapped Areas of Your Mind
                  </h1>
                  <p className="desc fw300 roboto">
                    The Silva Method is a proven meditation program and the mind control technique
                    for personal growth, health, intuition, and spiritual well-being.
                  </p>
                  <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                    get started
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src={MobileBannerImg2} className="d-md-none d-block w-100"
              alt="Slide 1" loading="lazy" />
            <img src={TabBannerImg2}
              className="d-none d-md-block d-lg-none w-100" alt="Slide 1" loading="lazy" />
            <img src={BannerImg2} className="d-none d-lg-block w-100" alt="Slide 1" loading="lazy" />
            <div className="carousel-caption">
              <div className="container">
                <div className="heroCont">
                  <p className="title poppins p1">
                    Start a Happy Life
                  </p>
                  <h1 className="h1 fw700 poppins">
                    Tap into the Untapped Areas of Your Mind
                  </h1>
                  <p className="desc fw300 roboto">
                    The Silva Method is a proven meditation program and the mind control technique
                    for personal growth, health, intuition, and spiritual well-being.
                  </p>
                  <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                    get started
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src={MobileBannerImg3} className="d-md-none d-block w-100"
              alt="Slide 1" loading="lazy" />
            <img src={TabBannerImg3}
              className="d-none d-md-block d-lg-none w-100" alt="Slide 1" loading="lazy" />
            <img src={BannerImg3} className="d-none d-lg-block w-100" alt="Slide 1" loading="lazy" />
            <div className="carousel-caption">
              <div className="container">
                <div className="heroCont">
                  <p className="title poppins p1">
                    Start a Happy Life
                  </p>
                  <h1 className="h1 fw700 poppins">
                    Tap into the Untapped Areas of Your Mind
                  </h1>
                  <p className="desc fw300 roboto">
                    The Silva Method is a proven meditation program and the mind control technique
                    for personal growth, health, intuition, and spiritual well-being.
                  </p>
                  <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                    get started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default HeroSection