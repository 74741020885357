import React from 'react'
import "./QuoteStyle.css";
import JoseImg from "../assets/jose-silva.webp"

const Quote = () => {
  return (
    <div>
        <section className="quoteSection pbtm">
      <div className="container">
        <div className="quoteCont flex-wrap flex-md-nowrap">
          <div className="profileImg">
            <img src={JoseImg} alt="#" loading="lazy" className="w100 h100" />
          </div>
          <div className="profileCont">
            <p className="title fw500 textDark roboto mb-0 pt-3 pt-md-0">
              "The greatest discovery you’ll ever make, is the potential of your own mind." -
              <span className="fw700 ms-1">José Silva</span>
            </p>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default Quote