import React from 'react'


import Newnavbar from '../components/Newnavbar'
import Footer from '../components/Footer'
import NewHeroSection from '../components/NewHeroSection'
import SendUsMessage from '../components/SendUsMessage'
import BannerImg from "../assets/contact-header.webp"
import BannerMobileImg from "../assets/instructorpage-for-mobile.webp"
import BannerTabImg from "../assets/instructorpage-for-tab.webp"


const Contact = () => {
  return (
    <div>
      <Newnavbar/>
      <NewHeroSection
        customClassName="contactPage"
        bannerImg={BannerImg}
        bannerTabImg= {BannerTabImg}
        bannerMobileImg= {BannerMobileImg}
        title="Reach Out and Contact Us Today"
        subtitle="Write to us. We would love to hear from you!"
        description=""
        buttonText="Get Started"
        />
      <SendUsMessage/>
      
      
      <Footer/>
    </div>
  )
}

export default Contact