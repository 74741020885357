import React from 'react'
import Navbar2 from '../components/Navbar2'
import Footer2 from '../components/Footer2'
import HeroSectionC99 from '../components/HeroSectionC99'
import ShouldJoinMasterclass from '../components/ShouldJoinMasterclass'
import Problems from '../components/Problems'
import Strength from '../components/Strength'
import SubconsciousMind from '../components/SubconsciousMind'
import AlphaState from '../components/AlphaState'
import SilvaDifference from '../components/SilvaDifference'
import ClassAgenda from '../components/ClassAgenda'
import VideoTestimonial from '../components/VideoTestimonial'
import FAQ2 from '../components/FAQ2'
import Bonus  from '../components/Bonus'
import Sticky  from '../components/Sticky'
import ClearDoubts from '../components/ClearDoubts'


const CourseLanding = () => {
  return (
    <div>
        <Navbar2/>
        <HeroSectionC99/>
        <Bonus/>
        <ShouldJoinMasterclass/>
        <Problems/>
        <Strength/>
        <SubconsciousMind/>
        <AlphaState/>
        <ClearDoubts/>
        <SilvaDifference/>
        <ClassAgenda/>
        <VideoTestimonial/>
        <FAQ2/>
        <Footer2/>
        <Sticky/>
    </div>
  )
}

export default CourseLanding