import React from 'react'
import './SickSectionStyle.css'
import sickman from '../assets/sick-man.webp'
import { Link } from 'react-scroll';


const SickSection = () => {
    return (
        <>
            <section className='sickSection ptop pbtm'>
                <div className='container'>
                    <div class="row align-items-center position-relative row-gap-4">
                        <div class="col-lg-6 col-xl-5 text-center text-lg-start">
                            <h2 class="h2 fw700 poppins textDark mb-3 mb-lg-4">বর্তমান মানুষ এত
                                <span class="gradientText ms-2">রোগাক্রান্ত কেন?</span>
                            </h2>
                            <p class="textDark roboto mb-3 mb-lg-4">
                                আধুনিক ব্যস্ততার জীবনে আমরা আমাদের শরীর ও মনের খেয়াল রাখতে ভুলে যাচ্ছি।প্রায়শই আমরা
                                শারীরিক এবং মানসিক স্বাস্থ্য চ্যালেঞ্জের সম্মুখীন হচ্ছি।অনিয়মিত জীবন যাপন, খাদ্যাভ্যাস এর সাথে
                                স্ট্রেস আমাদের জীবনে  নানা রকম জটিল রোগের উপদ্রব বাড়াচ্ছে।আমাদের মন এবং শরীরের মধ্যে
                                রয়েছে জটিল সংযোগ। যখন আমরা মানসিক চাপ অনুভব করি, তখন আমাদের শরীর কর্টিসল এবং
                                অ্যাড্রেনালিনের মতো হরমোন নিঃসরণ করে। যা আমাদেরকে সাধারণ সর্দি থেকে শুরু করে কার্ডিওভাসকুলার
                                রোগ এবং ডায়াবেটিসের মতো আরও গুরুতর অবস্থার অসুস্থতার জন্য আরও সংবেদনশীল করে তোলে।
                                এছাড়াও অর্থনৈতিক অস্থিতিশীলতা, সামাজিক বিচ্ছিন্নতা এবং পরিবেশগত উদ্বেগের মতো সামাজিক কারণগুলি
                                আমাদের জীবনে স্ট্রেস সম্পর্কিত রোগের মহামারীকে বাড়িয়ে তোলে।
                            </p>
                            <Link to="masterClassForm_health" smooth={true} duration={500}>
                            <button type="submit" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0 shadowLight">
                                মাস্টারক্লাসে যোগ দিন
                            </button>
                            </Link>
                        </div>
                        <div class="col-lg-6 col-xl-7">
                            <div class="videoArea ms-auto">
                                <img src={sickman} alt="#" loading="lazy" class="w100 h100 agendaImg" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SickSection