import React from 'react'
import './BenefitsHealthStyle.css'
import whitetick from '../assets/check-bg-white.svg'
import healthbenefits from '../assets/health-benefits.webp'

const BenefitsHealth = () => {
    return (
        <>
            <section className='benefitsHealthSection ptop pbtm silvaDiffSection'>
                <div className='container'>
                    <div class="row align-items-center position-relative row-gap-4">
                        <div class="col-lg-6 col-xl-5 text-center text-lg-start">
                            <h2 class="h2 fw600 poppins primaryWhite mb-3 mb-lg-4">
                                এই মাস্টারক্লাসে যোগদানের সুবিধা
                            </h2>
                            <ul className='resetUl'>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src={whitetick} alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">রোগ প্রতিরোধ ক্ষমতা বাড়ান</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src={whitetick} alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">পাচনতন্ত্র উন্নত করুন</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src={whitetick} alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">আপনার মন এবং শরীর শিথিল করুন</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src={whitetick} alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">চাপ এবং উদ্বেগ কমান</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src={whitetick} alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">কোনো ওষুধ ছাড়াই মাথাব্যথা থেকে মুক্তি পান</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src={whitetick} alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">ঘুমের মান উন্নত করুন</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src={whitetick} alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">ক্ষতিকারক নেশা গুলি ত্যাগ করুন</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src={whitetick} alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">ভালো অভ্যাস গড়ে তুলবেন</p>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-xl-7">
                            <div class="videoArea ms-auto">
                                <img src={healthbenefits} alt="#" loading="lazy" class="w100 h100 agendaImg" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BenefitsHealth