import React from 'react'
import "./WhyChooseStyle.css";
import Icon13 from "../assets/choose-us-icon-13.svg"
import Icon14 from "../assets/choose-us-icon-14.svg"
import Icon15 from "../assets/choose-us-icon-15.svg"
import Icon16 from "../assets/choose-us-icon-16.svg"
import Icon17 from "../assets/choose-us-icon-17.svg"
import Icon18 from "../assets/choose-us-icon-18.svg"


const WhyChoose = () => {
  return (
    <div>
        <section className="chooseUsSection text-center ptop pbtm lightBg">
      <p className="title fw600 poppins textDark text-capitalize">
        our specialties
      </p>
      <h2 className="h2 fw700 poppins textDark text-capitalize">
        why choose <span className="gradientText">silva method</span>
      </h2>
      <div className="iconWidget">
        <div className="widgetItems">
          <div className="itemsCont">
            <div className="cardFront">
              <div className="icon">
                <img src={Icon13} alt="#" loading="lazy" />
              </div>
              <div className="textCont text-start">
                <h3 className="mdFont fw700 poppins textDark m-0">500+</h3>
                <p className="fw600 poppins textDark text-capitalize m-0">instructors</p>
              </div>
            </div>
            <div className="cardBack"></div>
          </div>
        </div>
        <div className="widgetItems">
          <div className="itemsCont">
            <div className="cardFront">
              <div className="icon">
                <img src={Icon14} alt="#" loading="lazy" />
              </div>
              <div className="textCont text-start">
                <h3 className="mdFont fw700 poppins textDark m-0">12M+</h3>
                <p className="fw600 poppins textDark text-capitalize m-0">graduates</p>
              </div>
            </div>
            <div className="cardBack"></div>
          </div>
        </div>
        <div className="widgetItems">
          <div className="itemsCont">
            <div className="cardFront">
              <div className="icon">
                <img src={Icon15} alt="#" loading="lazy" />
              </div>
              <div className="textCont text-start">
                <h3 className="mdFont fw700 poppins textDark m-0">120+</h3>
                <p className="fw600 poppins textDark text-capitalize m-0">countries</p>
              </div>
            </div>
            <div className="cardBack"></div>
          </div>
        </div>
        <div className="widgetItems">
          <div className="itemsCont">
            <div className="cardFront">
              <div className="icon">
                <img src={Icon16} alt="#" loading="lazy" />
              </div>
              <div className="textCont text-start">
                <h3 className="mdFont fw700 poppins textDark m-0">4.6/<sub>5</sub></h3>
                <p className="fw600 poppins textDark text-capitalize m-0">amazon</p>
              </div>
            </div>
            <div className="cardBack"></div>
          </div>
        </div>
        <div className="widgetItems">
          <div className="itemsCont">
            <div className="cardFront">
              <div className="icon">
                <img src={Icon17} alt="#" loading="lazy" />
              </div>
              <div className="textCont text-start">
                <h3 className="mdFont fw700 poppins textDark m-0">4.7/<sub>5</sub></h3>
                <p className="fw600 poppins textDark text-capitalize m-0">facebook</p>
              </div>
            </div>
            <div className="cardBack"></div>
          </div>
        </div>
        <div className="widgetItems">
          <div className="itemsCont">
            <div className="cardFront">
              <div className="icon">
                <img src={Icon18} alt="#" loading="lazy" />
              </div>
              <div className="textCont text-start">
                <h3 className="mdFont fw700 poppins textDark m-0">1M+</h3>
                <p className="fw600 poppins textDark text-capitalize m-0">testimonials</p>
              </div>
            </div>
            <div className="cardBack"></div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default WhyChoose