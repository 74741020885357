import React from 'react'
import "./BonusStyle.css";
import DebalinaImg from "../assets/Debalina-Chatterjee.webp"
import { Link } from 'react-scroll';

import {handleScroll} from '../utils/animPulse'

const Bonus = () => {
  return (
    <div>
      <section className="bonusSection ptop lightBg">
        <div className="container">
          <h2 className="h2 fw700 poppins textDark text-center mb-2">
            Your Exclusive <span className="gradientText">Extra Bonus</span>
          </h2>
          <div className="instructorCard bonusCard">
            <div className="cardItem align-items-center">
              <div className="imgCont text-center">
                <div className="leftSide">
                  <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                </div>
                <h3 className="title fw600 poppins primaryWhite mb-2">
                  Debalina Chatterjee
                </h3>
                <p className="fw300 roboto primaryWhite shapeRect lightTrape mb-0">
                  Silva Instructor
                </p>
              </div>
              <div className="rightSide">
                <p className="desc fw300 primaryWhite poppins mb-4 d-flex align-items-start">
                  <span className="bonusPoints">Bonus #1</span>
                  A free guided meditation for resolving life's issues.
                </p>
                <p className="desc fw300 primaryWhite poppins mb-4 d-flex align-items-start">
                  <span className="bonusPoints">Bonus #2</span>
                  Download a complimentary workbook to overcome life challenges.
                </p>
                <p className="desc fw300 primaryWhite poppins mb-0 d-flex align-items-start">
                  <span className="bonusPoints">Bonus #3</span>
                  Upon successful completion of this masterclass, you'll receive a recognition Certificate from The Silva Method India.
                </p>
                <p className="desc fw300 primaryWhite poppins mb-0 d-flex align-items-start">
                  <span className="bonusPoints">Bonus #4</span>
                  After the masterclass, if you encounter any difficulties while practicing the
                  techniques, we're here to help. You'll get a free review call to discuss your doubts
                  after a month.
                </p>
                <Link to="masterClassForm" smooth={true} duration={500}>
                  <button type="button" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 mt-md-5 border-0" onClick={()=>handleScroll()} >
                    enroll now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Bonus