import React from 'react'
import "./TypeSettingStyle.css";
import LeafImage from "../assets/leaf.webp"

const TypeSetting = () => {
  return (
    <div>
      <section className="industrySection ptop pbtm">
        <div className="rightObj d-none d-lg-block">
          <img src={LeafImage} alt="#" loading="lazy" className="w100 h100" />
        </div>
        <div className="container">
          <div className="industryCont text-center">
            <p className="title fw600 poppins textDark mb-2">
              1 to 1 Counselling
            </p>
            <h2 className="h2 fw700 poppins textDark text-capitalize mb-4">
              Expert <span className="gradientText">Consultancy</span>
            </h2>
            <div className="d-flex align-items-start justify-content-center gap-3">
              <span className="spanNum">1</span>
              <p className="mb-0 p1 poppins">
                Receive a FREE 15-minute counselling session with one of our certified
                instructors via Zoom to help address any challenges you're facing.
              </p>
            </div>
            <a href="#" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape my-4">
              Get Free Counselling
            </a>
            <div className="d-flex align-items-start justify-content-center gap-3 mb-0">
              <span className="spanNum">2</span>
              <p className="mb-0 p1 poppins">
                If you find the free 15-minute consultation beneficial and desire more time for
                personalized support, you can schedule hourly counselling sessions.
              </p>
            </div>
            <div className="bookCard">
              <div className="outerBorder">
                <div className="cardItem">
                  <h3 className="h2 fw600 primaryWhite roboto mb-1">1 Hour</h3>
                  <p className="fw500 roboto primaryWhite">
                    Counselling Session
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-3 mb-3">
                    <p className="title fw600 roboto primaryWhite d-flex align-items-center justify-content-center mb-0">
                      <span className="me-1">₹</span>
                      <span className="fw600 roboto">700</span>
                    </p>
                    <p className="title oldPrice primaryWhite mb-0">
                      <span className="me-1">₹</span>
                      <span className="fw600 roboto">1400</span>
                    </p>
                  </div>
                  <a href="#" className="cardBtn">
                    Book Now
                  </a>
                </div>
              </div>
              <div className="outerBorder">
                <div className="cardItem">
                  <h3 className="h2 fw600 primaryWhite roboto mb-1">1.5 Hour</h3>
                  <p className="fw500 roboto primaryWhite">
                    Counselling Session
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-3 mb-3">
                    <p className="title fw600 roboto primaryWhite d-flex align-items-center justify-content-center mb-0">
                      <span className="me-1">₹</span>
                      <span className="fw600 roboto">900</span>
                    </p>
                    <p className="title oldPrice primaryWhite mb-0">
                      <span className="me-1">₹</span>
                      <span className="fw600 roboto">1800</span>
                    </p>
                  </div>
                  <a href="#" className="cardBtn">
                    Book Now
                  </a>
                </div>
              </div>
              <div className="outerBorder">
                <div className="cardItem">
                  <h3 className="h2 fw600 primaryWhite roboto mb-1">2 Hour</h3>
                  <p className="fw500 roboto primaryWhite">
                    Counselling Session
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-3 mb-3">
                    <p className="title fw600 roboto primaryWhite d-flex align-items-center justify-content-center mb-0">
                      <span className="me-1">₹</span>
                      <span className="fw600 roboto">1200</span>
                    </p>
                    <p className="title oldPrice primaryWhite mb-0">
                      <span className="me-1">₹</span>
                      <span className="fw600 roboto">2400</span>
                    </p>
                  </div>
                  <a href="#" className="cardBtn">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
            <p className="mb-5 poppins">
              (You will get - Expert consultation + Free Guided Meditation Session + Practice Module)
            </p>
            <div className="d-flex align-items-start justify-content-center gap-3 mb-4">
              <span className="spanNum">3</span>
              <p className="mb-0 p1 poppins">
                As a bonus, after completing one month of your paid counselling sessions, you'll
                have the opportunity to schedule a review call.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default TypeSetting