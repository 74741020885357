import React from 'react'
import "./DoForYouStyle.css";
import Icon19 from "../assets/events-btm-icon-19.svg"
import Icon20 from "../assets/events-btm-icon-20.svg"
import Icon21 from "../assets/events-btm-icon-21.svg"
import Icon22 from "../assets/events-btm-icon-22.svg"
import Icon23 from "../assets/events-btm-icon-23.svg"
import Icon24 from "../assets/events-btm-icon-24.svg"
import Icon25 from "../assets/events-btm-icon-25.svg"
import Icon26 from "../assets/events-btm-icon-26.svg"


const DoForYou = () => {
  return (
    <div>
        <section className="eventsBtmSection text-center pbtm lightBg">
      <p className="title fw600 poppins textDark">
        What can The Silva Method
      </p>
      <h2 className="h2 fw700 poppins textDark">
        Do for <span className="gradientText">You?</span>
      </h2>
      <div className="iconCards">
        <div className="cardRow">
          <div className="cardItem normalCard">
            <div className="icon">
              <img src={Icon19} alt="#" loading="lazy" />
            </div>
            <div className="textCont">
              <p className="fw600 poppins textDark text-capitalize m-0">stress</p>
            </div>
          </div>
          <div className="cardItem normalCard">
            <div className="icon">
              <img src={Icon20} alt="#" loading="lazy" />
            </div>
            <div className="textCont">
              <p className="fw600 poppins textDark text-capitalize m-0">anger</p>
            </div>
          </div>
          <div className="cardItem normalCard">
            <div className="icon">
              <img src={Icon21} alt="#" loading="lazy" />
            </div>
            <div className="textCont">
              <p className="fw600 poppins textDark text-capitalize m-0">depression</p>
            </div>
          </div>
          <div className="cardItem normalCard">
            <div className="icon">
              <img src={Icon22} alt="#" loading="lazy" />
            </div>
            <div className="textCont">
              <p className="fw600 poppins textDark text-capitalize m-0">sleep</p>
            </div>
          </div>
        </div>
        <div className="cardRow">
          <div className="cardItem reverseCard">
            <div className="icon">
              <img src={Icon23} alt="#" loading="lazy" />
            </div>
            <div className="textCont">
              <p className="fw600 poppins textDark text-capitalize m-0">weight loss</p>
            </div>
          </div>
          <div className="cardItem reverseCard">
            <div className="icon">
              <img src={Icon24} alt="#" loading="lazy" />
            </div>
            <div className="textCont">
              <p className="fw600 poppins textDark text-capitalize m-0">quit smoking</p>
            </div>
          </div>
          <div className="cardItem reverseCard">
            <div className="icon">
              <img src={Icon25} alt="#" loading="lazy" />
            </div>
            <div className="textCont">
              <p className="fw600 poppins textDark text-capitalize m-0">relationship</p>
            </div>
          </div>
          <div className="cardItem reverseCard">
            <div className="icon">
              <img src={Icon26} alt="#" loading="lazy" />
            </div>
            <div className="textCont">
              <p className="fw600 poppins textDark text-capitalize m-0">genius mind</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default DoForYou