import React from 'react'
import "../../components/AboutBLSStyle.css";


const AboutSls = ({title,colored,description}) => {
  return (
    <div>
        <section className="aboutBLSSection ptop pbtm lightBg">
            <div className="container">
                <h2 className="h2 fw700 poppins textDark text-capitalize mb-4">
                    {title} <span className="gradientText">{colored}</span>
                </h2>
                <p className="p1 textDark poppins">
                    {description}
                </p>
            </div>
        </section>
    </div>
  )
}

export default AboutSls