import React from 'react'
import "./KeyPointsStyle.css";
import CheckBGImg from "../assets/check-bg.svg"
import SkyBGImg from "../assets/sky.webp"

const KeyPoints = () => {
  return (
    <div>
        <section className="keyPointsSection ptop lightBg">
            <div id="clouds">
                <div className="cloud x1"></div>
                <div className="cloud x2"></div>
                <div className="cloud x3"></div>
                <div className="cloud x4"></div>
                <div className="cloud x5"></div>
            </div>
            <div className="container">
                <div className="pointsCont position-relative pbtm">
                    <h2 className="h2 fw700 poppins textDark text-center mb-4">
                        Key Points of the <span className="gradientText">Course</span>
                    </h2>
                    <ul className="resetUl">
                        <li className="d-flex align-items-start gap-2 mb-3">
                            <img src={CheckBGImg} alt="#" loading="lazy" />
                            <p className="textDark poppins mb-0">
                                Master functioning at the alpha brain wave level, utilizing both hemispheres for
                                enhanced consciousness.
                            </p>
                        </li>
                        <li className="d-flex align-items-start gap-2 mb-3">
                            <img src={CheckBGImg} alt="#" loading="lazy" />
                            <p className="textDark poppins mb-0">
                            Harness thought energy for genius-level performance and super-genius optimization.
                            </p>
                        </li>
                        <li className="d-flex align-items-start gap-2 mb-3">
                            <img src={CheckBGImg} alt="#" loading="lazy" />
                            <p className="textDark poppins mb-0">
                            Eliminate stress, fortify your immune system, and attain holistic health and well-being..
                            </p>
                        </li>
                        <li className="d-flex align-items-start gap-2 mb-3">
                            <img src={CheckBGImg} alt="#" loading="lazy" />
                            <p className="textDark poppins mb-0">
                            Acquire relaxation techniques to combat tension, fostering better productivity and health.
                            </p>
                        </li>
                        <li className="d-flex align-items-start gap-2 mb-3">
                            <img src={CheckBGImg} alt="#" loading="lazy" />
                            <p className="textDark poppins mb-0">
                            Purify your mental landscape, banishing limiting thoughts, beliefs, and language.
                            </p>
                        </li>
                        <li className="d-flex align-items-start gap-2 mb-3">
                            <img src={CheckBGImg} alt="#" loading="lazy" />
                            <p className="textDark poppins mb-0">
                            Eradicate thoughts, beliefs, and behaviors incongruent with your purpose and goals.
                            </p>
                        </li>
                        <li className="d-flex align-items-start gap-2 mb-3">
                            <img src={CheckBGImg} alt="#" loading="lazy" />
                            <p className="textDark poppins mb-0">
                            Learn natural sleep and wake cycles, waking refreshed without drugs or alarms.
                            </p>
                        </li>
                        <li className="d-flex align-items-start gap-2 mb-3">
                            <img src={CheckBGImg} alt="#" loading="lazy" />
                            <p className="textDark poppins mb-0">
                            Enhance stamina, alertness, and productivity, combating fatigue and accomplishing more.
                            </p>
                        </li>
                        {/* More list items */}
                    </ul>
                    <div className="text-center ptop">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#instructorModal1"
                            className="btnMain fw700 gradientBg primaryWhite text-capitalize shapeTrape"
                            fdprocessedid="zzienr">
                            enroll now
                        </button>
                    </div>
                </div>
            </div>
            <img src={SkyBGImg} alt="#" loading="lazy" className="w100 h100" />
        </section>
    </div>
  )
}

export default KeyPoints