import React from 'react'
import "./HeroSection_CoursesStyle.css";
import HeaderBGImg from "../assets/course-header.webp"
import CardThumbImg from "../assets/card-thumb-img.webp"
import CheckDarkImg from "../assets/check-dark.svg"

import HeaderMobileBGImg from "../assets/courses-header-for-mobile-tab.webp"
import HeaderTabBGImg from "../assets/courses-header-for-mobile-tab.webp"

const HeroSection_Courses = () => {
    
    const amount = 500;
    const currency = "INR";
    const receipt = "qqqq";

    const paymentHandler = async (e) => {
             
      const response = await fetch ("http://localhost:8080/order", {
        method: "POST",
        body: JSON.stringify({
            amount,
            currency,
            receipt: receipt,
        }),
        headers:{
              "Content-Type": "application/json",
        }
      });
       const order = await response.json();
       console.log(order);



       var options = {
        "key": "rzp_test_RJvswW0ADyeF2K", // Enter the Key ID generated from the Dashboard
        amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency,
        "name": "Silva India", //your business name
        "description": "Test Transaction",
        "image": "https://example.com/your_logo",
        "order_id": order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": async function (response){
            alert(response.razorpay_payment_id);
            alert(response.razorpay_order_id);
            alert(response.razorpay_signature)

        //     const body ={
        //         ...response,

        //     };
        //    const validateRes = await  fetch("http://localhost:8080/order/validate", {
        //     method: "POST",
        //     body: JSON.stringify(body),
        //     headers: {
        //         "Content-Type" : "application/json",
        //     },
        //    });

        //    const jsonRes = await validateRes.json();
        //    console.log(jsonRes);

        },
        "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
            "name": "customer name", //your customer's name
            "email": "customer email", 
            "contact": "customer phn"  //Provide the customer's phone number for better conversion rates 
        },
        "notes": {
            "address": "Razorpay Corporate Office"
        },
        "theme": {
            "color": "#3399cc"
        }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response){
                alert(response.error.code);
                alert(response.error.description);
                alert(response.error.source);
                alert(response.error.step);
                alert(response.error.reason);
                alert(response.error.metadata.order_id);
                alert(response.error.metadata.payment_id);
        });
        rzp1.open();
        e.preventDefault();

    };



  return (
    <div>
        <section className="heroSection lightBg position-relative coursePage">
            {/* <img src={HeaderBGImg} className="d-block w-100" alt="#" loading="lazy" /> */}
             
            <img src={HeaderMobileBGImg} class="d-md-none d-block w-100" alt="#"
                loading="lazy"/>
            <img src={HeaderTabBGImg} class="d-none d-md-block d-lg-none w-100"
                alt="#" loading="lazy"/>
            <img src={HeaderBGImg}class="d-none d-lg-block w-100 bannerLg" alt="#" loading="lazy"/>
            
            <div className="carousel-caption">
                <div className="container">
                    <div className="d-flex justify-content-between totalContArea">
                        <div className="heroCont">
                            <h1 className="h1 fw700 poppins">
                                The Silva Basic Lecture Series
                            </h1>
                            <p className="desc fw300 roboto">
                                An amalgamation of the Silva Life System and Silva Intuition System, the heart of the
                                Silva Method Training, designed by Jose Silva!
                            </p>
                            <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                                know more
                            </a>
                        </div>
                        <div className="rightCard">
                            <div className="thumbImg">
                                <img src={CardThumbImg} alt="#" loading="lazy" className="w-100 h100" />
                            </div>
                            <p className="fw500 roboto textDark shapeRect lightTrape mt-3 mb-2">
                                Course Price
                            </p>
                            <div className="d-flex align-items-center flex-wrap column-gap-4 row-gap-1 mb-4 mb-md-2">
                                <p className="p1 fw900 roboto textDark d-flex align-items-center justify-content-center mb-0">
                                    <span className="me-1">₹</span>
                                    <span className="roboto">900</span>
                                </p>
                                <p className="p1 fw900 oldPrice textLight mb-0">
                                    <span className="me-1">₹</span>
                                    <span className="roboto">1800</span>
                                </p>
                                <p className="fw600 roboto textDark mb-0">
                                    You will get 20% Off
                                </p>
                            </div>
                            <div className="d-flex align-items-center flex-wrap mb-5">
                                <p className="title fw700 poppins textDark mb-0">4.6</p>
                                <div className="d-flex align-items-center ratingStarIcon ms-2 me-4">
                                    <span className="material-symbols-outlined">
                                        star
                                    </span>
                                    <span className="material-symbols-outlined">
                                        star
                                    </span>
                                    <span className="material-symbols-outlined">
                                        star
                                    </span>
                                    <span className="material-symbols-outlined">
                                        star
                                    </span>
                                    <span className="material-symbols-outlined">
                                        star_half
                                    </span>
                                </div>
                                <p className="fw600 roboto textDark mb-0">
                                    (108,283 ratings)
                                </p>
                            </div>
                            <h3 className="title fw700 poppins textDark mb-3">
                                Unlock Unlimited Perks
                            </h3>
                            <ul className="resetUl unlockList">
                                <li className="d-flex align-items-center gap-2 mb-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy" />
                                    <p className="textDark roboto mb-0">Life Changing Transformation Courses</p>
                                </li>
                                {/* More list items */}
                                <li class="d-flex align-items-center gap-2 mb-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy"/>
                                    <p class="textDark roboto mb-0">Powerful Silva Method Meditation Techniques</p>
                                </li>
                                <li class="d-flex align-items-center gap-2 mb-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy"/>
                                    <p class="textDark roboto mb-0">Mind Control Tools and Training</p>
                                </li>
                                <li class="d-flex align-items-center gap-2 mb-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy"/>
                                    <p class="textDark roboto mb-0">Access to a Global Community</p>
                                </li>
                                <li class="d-flex align-items-center gap-2 mb-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy"/>
                                    <p class="textDark roboto mb-0">Doubt Clearing and Healing Session</p>
                                </li>
                                <li class="d-flex align-items-center gap-2 mb-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy"/>
                                    <p class="textDark roboto mb-0">Weekly Meetups for Silva Graduates</p>
                                </li>
                                <li class="d-flex align-items-center gap-2">
                                    <img src={CheckDarkImg} alt="#" loading="lazy"/>
                                    <p class="textDark roboto mb-0">Instant Money Back Guarantee</p>
                                </li>
                            </ul>
                            <div className="eventDate">
                                <div className="d-flex align-items-center justify-content-between">
                                    <p className="fw700 poppins textDark mb-0">
                                        Event <span className="gradientText">Dates</span>
                                    </p>
                                    <p className="fw500 roboto primaryWhite shapeRect gradientBg mb-0">
                                        2024
                                    </p>
                                </div>
                                <div className="dateBoxes">
                                <div class="dateBox">
                                        <div class="h2 fw600 roboto textDark innerBox">04</div>
                                        <p class="title poppins textDark mb-0">May</p>
                                    </div>
                                    <div class="dateBox">
                                        <div class="h2 fw600 roboto textDark innerBox">05</div>
                                        <p class="title poppins textDark mb-0">May</p>
                                    </div>
                                    <div class="dateBox">
                                        <div class="h2 fw600 roboto textDark innerBox">11</div>
                                        <p class="title poppins textDark mb-0">May</p>
                                    </div>
                                    <div class="dateBox">
                                        <div class="h2 fw600 roboto textDark innerBox">12</div>
                                        <p class="title poppins textDark mb-0">May</p>
                                    </div>
                                    <div class="dateBox">
                                        <div class="h2 fw600 roboto textDark innerBox">12</div>
                                        <p class="title poppins textDark mb-0">May</p>
                                    </div>
                                    <div class="dateBox">
                                        <div class="h2 fw600 roboto textDark innerBox">12</div>
                                        <p class="title poppins textDark mb-0">May</p>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                {/* <button type="button" onClick={paymentHandler} className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape btnPulse">
                                    enroll now
                                </button> */}
                                <div class="text-center">
                                {/* <button type="button" onClick={paymentHandler} class="text-capitalize fw700 primaryBlack btnPulse">
                                    enroll now
                                </button> */}
                                <button type="button"  class="text-capitalize fw700 primaryBlack btnPulse">
                                    Coming Soon
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default HeroSection_Courses