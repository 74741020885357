import React from 'react'
import './AboutInstructorStyle.css'
import instructor1 from '../assets/Debasish-Chatterjee.webp'
import instructor2 from '../assets/dr-kalam.webp'
import { Link } from 'react-scroll';

const AboutInstructor = () => {
    const cardData = [
        {
            id: 1,
            instructorimg: instructor1,
            instructortitle: 'দেবাশীষ চ্যাটার্জী',
            instructordesc: `একজন সার্টিফায়েড সিলভা মেথড প্রশিক্ষকের ভূমিকায়, তাঁর senior accountant
            হিসাবে প্রায় 40 বছরের দক্ষতা এবং আধ্যাত্মিক অন্তর্দৃষ্টি মসৃণভাবে একত্রিত হয়। পাঁচ
            দশকেরও বেশি সময় ধরে নিবেদিত আধ্যাত্মিক অনুশীলন তাঁর  গভীর যাত্রাকে সংজ্ঞায়িত
            করে।তাঁর লক্ষ্য হল ছাত্রদের ক্ষমতায়ন করা, আর্থিক দক্ষতা এবং আধ্যাত্মিকতা উভয় ক্ষেত্রেই
            প্রসিদ্ধ করা। আপনার অভ্যন্তরীণ উজ্জ্বলতাকে খুঁজে বের করতে এবং আপনার লক্ষ্যগুলি
            অর্জন করতে তিনি আপনাকে সাহায্য করবেন।একজন লাইফ ট্রান্সফরমেশন কোচ হিসাবে তিনি
            মানুষকে তাদের জীবনে ইতিবাচক শক্তি খুঁজে পেতে সাহায্য করেন।  সিলভা পদ্ধতির মাধ্যমে,
            তিনি আপনার সত্যিকারের বাস্তবতাকে রূপ দেওয়ার চেষ্টা করেন।তিনি ব্যক্তিদের তাদের ভাগ্য নিয়ন্ত্রণ
            করতে এবং তাদের অভ্যন্তরীণ সম্ভাবনা আবিষ্কার করতে সহায়তা করার জন্য প্রতিশ্রুতিবদ্ধ।`
        },
        {
            id: 2,
            instructorimg: instructor2,
            instructortitle: 'ডা. কালাম ফারুক',
            instructordesc: `ডাঃ আবুল কালাম 1967 সালে বাংলাদেশে জন্মগ্রহণ করেন। তিনি তার পড়াশোনা 
            শেষ করেন এবং 1998 সালে ICAM থেকে মেডিকেল সায়েন্সে স্নাতক হন। ডাঃ আবুল কালাম একজন জেনারেল 
            ফিজিশিয়ান হিসেবে তার কর্মজীবন শুরু করেন।2001 সালে, তিনি বাংলাদেশের সিলভা কিংবদন্তি জনাব মাহি কাজীর 
            কাছ থেকে জোসে সিলভার সিলভা পদ্ধতি সম্পর্কে জানতে পারেন। 2001 থেকে 2005 সাল পর্যন্ত, ডাঃ কালাম 40 টিরও 
            বেশি সেমিনারে অংশ নিয়ে জনাব কাজীর কাছ থেকে সিলভা কোর্সের পুরো পরিসর নিয়েছিলেন। 2006 থেকে তিনি ব্যক্তিগতভাবে 
            জনাব কাজীর অধীনে প্রশিক্ষণ নেন, সংগঠক, ব্যবস্থাপক এবং ইন্সট্রাক্ট্ররের দায়িত্ব পালন করেন। শুধু তাই নয় তিনি ISOMETRIC 
            LTD-এর একটি গুরুত্বপূর্ণ অংশ ছিলেন। তিনি সিলভা পদ্ধতি এবং জনাব কাজীর কাছ থেকে অনেক কিছু শিখেছেন, বিশেষ করে 
            কীভাবে মানুষকে ভালোবাসতে হয় এবং সাহায্য করতে হয়।`
        },
    ]

    return (
        <>
            <section className="aboutInstructorSection lightBg ptop pbtm">
                <div className="container">
                    <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-5 text-center">
                        আপনার <span class="gradientText">ইন্সট্রাক্টরের সম্পর্কে</span> কিছু কথা
                    </h2>
                    <div className="healthInstructorCard">
                        {
                            cardData.map((item, idx) =>
                                <div className="cardItem">
                                    <div class="leftSide">
                                        <img src={item.instructorimg} alt="#" loading="lazy" class="w100 h100" />
                                    </div>
                                    <div className="rightSide">
                                        <h3 class="mdFont textDark fw700 poppins mb-3">{item.instructortitle}</h3>
                                        <p class="desc textDark fw400 poppins mb-0">{item.instructordesc}</p>
                                    </div>
                                </div>
                            )
                        }
                        <div className="text-center">
                        <Link to="masterClassForm_health" smooth={true} duration={500}>
                            <button type="submit" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-5 border-0 shadowLight">
                                মাস্টারক্লাসে নথিভুক্ত করুন
                            </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutInstructor