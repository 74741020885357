import React from 'react'
import './SilvaFounderStyle.css'
import josesilva from '../assets/josh-silva-health-masterclass.webp'

const SilvaFounder = () => {
    return (
        <>
            <section className='silvaFounderSection ptop pbtm'>
                <div className='container'>
                    <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-5 text-center">
                        Silva Method- এর <span class="gradientText ms-2">অগ্রগামী এবং প্রতিষ্ঠাতা</span>
                    </h2>
                    <p class="p1 textDark roboto mb-5 text-center m-auto">
                        José Silva হলেন Silva Method- এর প্রতিষ্ঠাতা, এবং mind empowerment
                        গবেষণার পথিকৃৎ। সিলভা মানুষের মনের লুকানো সম্ভাবনা জাগ্রত করার জন্য
                        তাঁর জীবন উৎসর্গ করেছিলেন। 22 বছরের গবেষণার পর, তিনি 1966 সালে
                        তাঁর ফলাফল প্রকাশ করেছেন।তাঁর আবিষ্কার কে নিখুঁত এবং মানুষকে শিক্ষাদানের
                        জন্য বাকি জীবন অতিবাহিত করেন।সিলভার উত্তরাধিকার আজ Silva
                        International- এর মাধ্যমে পরিচালিত হয়, একটি সংস্থা যা তাঁর মিশন চালিয়ে
                        যাওয়ার জন্য প্রতিশ্রুতিবদ্ধ - যা মননশীল অনুশীলনের মাধ্যমে জীবনকে উন্নত করার
                        লক্ষ্যে নিবেদিত।
                    </p>
                    <div class="videoArea m-auto">
                        <img src={josesilva} alt="#" loading="lazy" class="w100 h100 agendaImg" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default SilvaFounder