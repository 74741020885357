import React from 'react'
import "./AboutBLSStyle.css";


const AboutBLS = () => {
  return (
    <div>
        <section className="aboutBLSSection ptop pbtm lightBg">
            <div className="container">
                <h2 className="h2 fw700 poppins textDark text-capitalize mb-4">
                    about the silva basic lecture <span className="gradientText">series</span>
                </h2>
                <p className="p1 textDark poppins">
                    The Silva Basic Lecture Series Seminar is your gateway to a transformative journey of personal
                    growth, and enlightenment. This seminar is the starting point for your Silva Method journey, and it
                    serves as the core and foundation for all other advanced seminars, including the Graduate Lecture
                    Series Seminar and the Ultra Dynamic Energy Healing Seminar. It's the moment when you take the first
                    step towards a new chapter in your life, filled with boundless opportunities and profound
                    transformation. Step into a world of boundless potential with the Silva BLS Seminar, where the power
                    of your mind knows no limits.
                </p>
            </div>
        </section>
    </div>
  )
}

export default AboutBLS