import React from "react";
import './App.css';
// import "./index.css";
import Home from "./routes/Home";
import About from "./routes/About";
import Courses from "./routes/Courses";
import Contact from "./routes/Contact";
import { Route, Routes } from "react-router-dom"
import Instructor from "./routes/Instructor";
import Solution from "./routes/Solution";
import CourseLanding from "./routes/CourseLanding";
import ThankYou from "./routes/ThankYou";
import LeadPage from "./routes/LeadPage";
import LeadPageOne from "./routes/LeadPageOne";
import SLSproblemFixer from "./routes/digital-course/SLSproblemFixer";
import HealthLanding from "./routes/HealthLanding";
import CourseSLS from "./routes/Sls/CourseSLS";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Instructor />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/instructor" element={<Instructor />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/emotion_mastery_99_workshop" element={<CourseLanding />} />
        <Route path="/masterclass-on-health" element={<HealthLanding />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/lead-page" element={<LeadPage />} />
        <Route path="/lead-page1" element={<LeadPageOne />} />
        <Route path="/SLS-problem-fixer" element={<SLSproblemFixer />} />
        <Route path="/silva-life-system" element={<CourseSLS/>}/>
      </Routes>
    </>
  );
}

export default App;
