import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import "./NavbarStyle.css";
import LogoImg from "../assets/silva-india-logo-white.webp";

const Newnavbar = () => {
  const location = useLocation();

  // Define an array of objects for your navbar links
  const navLinks = [
    { to: '/', text: 'Home' },
    { to: '/about', text: 'About', sublinks: [
      { to: '/instructor', text: 'Instructors' },
      { to: '/solution', text: 'Solutions We Provide' },
      // Add other sublinks under About
    ]},
    { to: '/courses', text: 'Courses' },
    { to: '/contact', text: 'Contact' },
    // Add other links
  ];

  return (
    <div>
      <section className="navSection">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand" href="index.html">
              <img src={LogoImg} alt="#" loading="lazy" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#staticBackdrop" aria-controls="staticBackdrop" aria-label="Toggle navigation">
            </button>

            <div className="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop"
              aria-labelledby="staticBackdropLabel">
              <div className="offcanvas-header">
                <a className="navbar-brand" href="index.html">
                  <img src={LogoImg} alt="#" loading="lazy" />
                </a>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {navLinks.map((link) => (
                  <li key={link.to} className="nav-item">
                    {link.sublinks ? (
                      <li className="nav-item dropdown">
                        <NavLink exact to={link.to} className="nav-link" activeClassName="active">
                          {link.text} <span><i className="fa-solid fa-angle-down"></i></span>
                        </NavLink>
                        <ul className="dropdown-menu">
                          {link.sublinks.map((sublink) => (
                            <li key={sublink.to}>
                              <NavLink to={sublink.to} className="dropdown-item" activeClassName="active">{sublink.text}</NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ) : (
                      <NavLink exact to={link.to} className="nav-link" activeClassName="active">{link.text}</NavLink>
                    )}
                  </li>
                ))}
                <li className="nav-item">
                  <a className="nav-link text-light" href="https://silvamethod.com" target="_blank">Silva International</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </div>
  );


  
}

export default Newnavbar;




// import React from 'react';
// import { NavLink, useLocation } from 'react-router-dom';
// import LogoImg from "../assets/silva-india-logo-white.webp";

// const Navbar = () => {
//   const location = useLocation();

//   // Define an array of objects for your navbar links
//   const navLinks = [
//     { to: '/', text: 'Home' },
//     { to: '/about', text: 'About', sublinks: [
//       { to: '/instructor', text: 'Instructors' },
//       { to: '/solution', text: 'Solutions We Provide' },
//       // Add other sublinks under About
//     ]},
//     { to: '/courses', text: 'Courses' },
//     { to: '/contact', text: 'Contact' },
//     // Add other links
//   ];

//   // Function to render the correct link for each tab
//   const renderNavLink = (link) => {
//     if (link.sublinks && link.sublinks.length > 0) {
//       // If tab has sublinks, render the first sublink
//       return (
//         <NavLink to={link.sublinks[0].to} className="nav-link">{link.text}</NavLink>
//       );
//     } else {
//       // If tab doesn't have sublinks, render the main link
//       return (
//         <NavLink exact to={link.to} className="nav-link" activeClassName="active">{link.text}</NavLink>
//       );
//     }
//   };

//   return (
//     <div>
//       <section className="navSection">
//         <nav className="navbar navbar-expand-lg">
//           <div className="container">
//             <NavLink className="navbar-brand" to="/">
//               <img src={LogoImg} alt="#" loading="lazy" />
//             </NavLink>
//             <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
//               data-bs-target="#staticBackdrop" aria-controls="staticBackdrop" aria-label="Toggle navigation">
//             </button>

//             <div className="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop"
//               aria-labelledby="staticBackdropLabel">
//               <div className="offcanvas-header">
//                 <NavLink className="navbar-brand" to="/">
//                   <img src={LogoImg} alt="#" loading="lazy" />
//                 </NavLink>
//                 <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
//                   aria-label="Close"></button>
//               </div>
//               <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
//                 {navLinks.map((link) => (
//                   <li key={link.to} className="nav-item">
//                     {link.sublinks ? (
//                       <li className="nav-item dropdown">
//                         {renderNavLink(link)}
//                         <ul className="dropdown-menu">
//                           {link.sublinks.map((sublink) => (
//                             <li key={sublink.to}>
//                               <NavLink to={sublink.to} className="dropdown-item" activeClassName="active">{sublink.text}</NavLink>
//                             </li>
//                           ))}
//                         </ul>
//                       </li>
//                     ) : (
//                       renderNavLink(link)
//                     )}
//                   </li>
//                 ))}
//                 <li className="nav-item">
//                   <NavLink className="nav-link" to="#">Silva International</NavLink>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </nav>
//       </section>
//     </div>
//   );
// }

// export default Navbar;

// import React from 'react';
// import { NavLink, useLocation } from 'react-router-dom';
// import LogoImg from "../assets/silva-india-logo-white.webp";

// const Navbar = () => {
//   const location = useLocation();

//   // Define an array of objects for your navbar links
//   const navLinks = [
//     { to: '/', text: 'Home' },
//     { to: '/about', text: 'About', sublinks: [
//       { to: '/instructor', text: 'Instructors' },
//       { to: '/solution', text: 'Solutions We Provide' },
//       // Add other sublinks under About
//     ]},
//     { to: '/courses', text: 'Courses' },
//     { to: '/contact', text: 'Contact' },
//     // Add other links
//   ];

//   // Function to render the correct link for each tab
//   const renderNavLink = (link) => {
//     if (link.sublinks && link.sublinks.length > 0) {
//       // If tab has sublinks, render the first sublink
//       return (
//         <NavLink to={link.sublinks[0].to} className="nav-link">{link.text}</NavLink>
//       );
//     } else {
//       // If tab doesn't have sublinks, render the main link
//       return (
//         <NavLink exact to={link.to} className={`nav-link ${location.pathname === link.to ? 'active' : ''}`}>{link.text}</NavLink>
//       );
//     }
//   };

//   return (
//     <div>
//       <section className="navSection">
//         <nav className="navbar navbar-expand-lg">
//           <div className="container">
//             <NavLink className="navbar-brand" to="/">
//               <img src={LogoImg} alt="#" loading="lazy" />
//             </NavLink>
//             <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
//               data-bs-target="#staticBackdrop" aria-controls="staticBackdrop" aria-label="Toggle navigation">
//             </button>

//             <div className="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop"
//               aria-labelledby="staticBackdropLabel">
//               <div className="offcanvas-header">
//                 <NavLink className="navbar-brand" to="/">
//                   <img src={LogoImg} alt="#" loading="lazy" />
//                 </NavLink>
//                 <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
//                   aria-label="Close"></button>
//               </div>
//               <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
//                 {navLinks.map((link) => (
//                   <li key={link.to} className="nav-item">
//                     {link.sublinks ? (
//                       <li className="nav-item dropdown">
//                         {renderNavLink(link)}
//                         <ul className="dropdown-menu">
//                           {link.sublinks.map((sublink) => (
//                             <li key={sublink.to}>
//                               <NavLink to={sublink.to} className="dropdown-item" activeClassName="active">{sublink.text}</NavLink>
//                             </li>
//                           ))}
//                         </ul>
//                       </li>
//                     ) : (
//                       renderNavLink(link)
//                     )}
//                   </li>
//                 ))}
//                 {/* Add the external link here */}
//                 <li className="nav-item">
//                   <a href="https://example.com" className="nav-link external-link">External Link</a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </nav>
//       </section>
//     </div>
//   );
// }

// export default Navbar;




