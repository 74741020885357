import React from 'react'
import './HappyLifeStyle.css'
import happylife from '../assets/happy-life.webp'

const HappyLifeSection = () => {
    return (
        <>
            <section className='happyLifeSection ptop pbtm'>
                <div className='container text-center'>
                    <p class="fw700 poppins textDark shapeRect lightTrape mb-4">Silva Method</p>
                    <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-5 text-center">
                        রোগমুক্ত সুখী
                        <span class="gradientText ms-2">জীবনের চাবিকাঠি</span>
                    </h2>
                    <p class="p1 textDark roboto mb-5 text-center m-auto">
                        দীর্ঘস্থায়ী মানসিক চাপের মধ্যে থাকা লোকেরা প্রায়শই ধূমপান ও মদ্যপানের মত ক্ষতিকারক নেশায়
                        আসক্ত হয়ে পড়েন।যা বিভিন্ন রোগের বিকাশে অবদান রাখে। অতিরিক্ত স্ট্রেস ঘুমেরও ব্যাঘাত ঘটায়
                        এবং উদ্বেগ এবং বিষণ্নতারকেও বাড়িয়ে তোলে।দামি দামি ওষুধও যেসব শারীরিক ও মানসিক সমস্যা
                        থেকে মুক্তি দিতে পারেনা, সিলভা মেথড - এর সাহায্যে সেসব সমস্যার সমাধান খুব সহজে নিজেই
                        করে ফেলতে পারবেন।
                    </p>
                    <p class="p1 textDark roboto mb-5 text-center m-auto">
                        সিলভা মেথড - প্রাচীন মেডিটেশন এবং আধুনিক বৈজ্ঞানিক পদ্ধতির একত্রীকরণ।এটি কিছু সহজ মেডিটেশন
                        পদ্ধতির মাধ্যমে আপনাকে আপনার আলফা ( হিউম্যান ইন্টেলিজেন্স লেভেল ) এবং থিটা ( বায়োলজিক্যাল ইন্টেলিজেন্স লেভেল )
                        স্তরে পৌঁছাতে সাহায্য করবে। এর ফলে আপনি আপনার শরীর, মন, ও মস্তিষ্কে এক গভীর শান্তি অনুভব করবেন।যা আপনার স্ট্রেস,
                        উদ্বেগ, বিসন্নতা দূর করে, আন্তরিক প্রশান্তি প্রদান করে।শুধু তাই নয় এটি আপনার ইমিউনিটি সিস্টেম কেও শক্তিশালী করে তুলে,
                        বিভিন্ন রোগ সংক্রমণের হাত থেকে রক্ষা করে। নিয়মিত সিলভা মেথডের অনুশীলন মন, শরীর এবং আত্মার মধ্যে একটি ভারসাম্যও গড়ে তোলে,
                        যা প্রাণবন্ত স্বাস্থ্য এবং সুস্থতা বজায় রাখার জন্য অপরিহার্য।
                    </p>
                    <div class="videoArea m-auto">
                        <img src={happylife} alt="#" loading="lazy" class="w100 h100 agendaImg" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default HappyLifeSection