import React from 'react'
import "./AlphaStateStyle.css";
import CheckImg from "../assets/check-bg.svg"
// import AlphaVideo from "../assets/Alpha State of Mind.mp4"
import { Link } from 'react-scroll';
import ReactPlayer from 'react-player';
import {handleScroll} from '../utils/animPulse'
const AlphaVideo1 ="https://vz-6873ed28-3a2.b-cdn.net/f2a35207-8f60-46a6-96b8-8331107172b1/playlist.m3u8"


const AlphaState = () => {
  return (
    <div>
        <section className="alphaStateSection lightBg ptop pbtm position-relative">
      <div id="clouds">
        <div className="cloud x1"></div>
        <div className="cloud x2"></div>
        <div className="cloud x3"></div>
        <div className="cloud x4"></div>
        <div className="cloud x5"></div>
        <div className="cloud x6"></div>
        <div className="cloud x7"></div>
        <div className="cloud x8"></div>
        <div className="cloud x9"></div>
        <div className="cloud x10"></div>
      </div>
      <div className="container position-relative">
        <h2 className="h2 fw700 poppins textDark mb-4 mb-lg-5 text-center">
          Explore more about <span className="gradientText">The Alpha State of Mind</span>
        </h2>
        <p className="p1 textDark roboto mb-5 text-center m-auto">
          When we fall asleep and right after waking up from a good sleep, the human brain enters the alpha
          state of mind. Where our brain frequencies range from 7 to 14 Hz per second. The Alpha state
          connects our conscious and subconscious minds. It helps us relax deeply while still being aware of
          what's happening around us. And the calmness makes our minds more open to learning new things and
          collecting information to solve problems.
        </p>
        <div className="videoArea m-auto">
        {/* <video autoPlay muted loop width="100%" height="100%">
            <source src={AlphaVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
          <ReactPlayer
                  url={AlphaVideo1}
                  // controls
                  width="100%"
                  height="100%"
                  playing
                  muted
                  loop // Add the loop prop to enable looping
                  // config={{
                  //   hlsOptions: {
                  //     // You can add additional HLS options here if needed
                  //   }
                  // }}
                />
          
        </div>
        <div className="benefitsPoints m-auto">
          <p className="mdFont fw600 poppins textDark text-center mt-5">
            The benefits of using this state
          </p>
          <ul className="resetUl">
            <li className="d-flex align-items-start gap-2 mb-3">
              <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
              <p className="textDark poppins mb-0">
                <span className="fw600">Boost Creativity:</span> Alpha state enhances creative thinking and
                problem-solving skills.
              </p>
            </li>
            <li className="d-flex align-items-start gap-2 mb-3">
              <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
              <p className="textDark poppins mb-0">
                <span className="fw600">Reduce Stress:</span> Promotes relaxation, lowers anxiety, and
                decreases cortisol levels.
              </p>
            </li>
            <li className="d-flex align-items-start gap-2 mb-3">
              <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
              <p className="textDark poppins mb-0">
                <span className="fw600">Increase Intuition:</span> Heightens intuitive abilities and gut
                feelings.
              </p>
            </li>
            <li className="d-flex align-items-start gap-2 mb-3">
              <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
              <p className="textDark poppins mb-0">
                <span className="fw600">Improve Learning:</span> Enhances information retention and
                cognitive performance.
              </p>
            </li>
            <li className="d-flex align-items-start gap-2 mb-3">
              <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
              <p className="textDark poppins mb-0">
                <span className="fw600">Enhance Visualization:</span> Heightens visualization abilities to
                achieve goals.
              </p>
            </li>
            <li className="d-flex align-items-start gap-2 mb-3">
              <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
              <p className="textDark poppins mb-0">
                <span className="fw600">Increase Mindfulness:</span> Deepens meditation, and fosters
                self-awareness, and introspection.
              </p>
            </li>
            <li className="d-flex align-items-start gap-2 mb-3">
              <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
              <p className="textDark poppins mb-0">
                <span className="fw600">Heal Naturally:</span> Our body can heal itself naturally while
                being at Alpha State.
              </p>
            </li>
          </ul>
          <div className="text-center">
          <Link to="masterClassForm" smooth={true} duration={500}>
            <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0 shadowLight" onClick={()=>handleScroll()}> 
              enroll now
            </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default AlphaState