import React from 'react'
import Navbar2 from '../components/Navbar2'
import LeadHero from '../components/LeadHero'
import Quote from '../components/Quote'
import AllAbout from '../components/AllAbout'




const LeadPage = () => {
  return (
    <div>
        <Navbar2/>
        <LeadHero/>
        <Quote/>
        <AllAbout/>

    </div>
  )
}

export default LeadPage