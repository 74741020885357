import React, { useState } from 'react'
import "./TalkInstructorStyle.css";
import ConfusedImage from "../assets/confused.webp"
import axios from 'axios';

const TalkInstructor = () => {
 

  const [formData, setFormData] = useState({
    fname: '',
    mobile: '',
    email: '',
    phn: '',
    inputDate: '',
    msg: ''


    
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://dedicated-nurture-a182b31812.strapiapp.com/api/contact-forms', {
        data: {
          Name: formData.fname,
          Mobile: formData.mobile,
          Email: formData.email,
          Whatsapp: formData.phn,
          Date_Requested: formData.inputDate,
          Query: formData.msg
        }
      },
      {
        headers: {
          Authorization: 'Bearer c510b6822985a819f5b367fa7d5f401b695bd20e89da1913347972178dea7177cdb94f7fc41a8d6d09b4df35984307bb230f28002ef174ea58a582a4af0dcb973020de79de440acab20eba3efc8954398e37a9353f63fedadf5e707a07c3b3d47821e47100f7c7ad4548fb9fad7310d47129aecbbabb436686891abf220dba0c'
          // Replace 'YOUR_API_TOKEN' with your actual API token
        }
      }
    );
      console.log('Response:', response.data);
      // Reset form fields after successful submission
      setFormData({
        fname: '',
        mobile: '',
        email: '',
        phn: '',
        inputDate: '',
        msg: ''
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };



  return (
    <div>
         <section className="talkInstructorSection ptop pbtm lightBg">
      <div className="container">
        <div className="contArea text-center">
          <h2 className="h2 fw700 poppins textDark text-capitalize">
            still <span className="gradientText">confused?</span>
          </h2>
          <p className="title fw600 poppins textDark">
            Talk to an Instructor for <span className="span1">Free</span>
          </p>
          <p className="desc fw300 textDark poppins mb-5">
            If you are still confused about what the Silva Method Courses are all about and how they can bring positive and rapid transformation to your life, no need to worry. You can now talk to an expert for Free. Simply fill in the form with your details and wait for your call with the instructor.
          </p>
          <div className="row">
            <div className="col-md-6">
              <div className="leftForm">
                <p className="fw600 poppins textDark mb-0">
                  Submit the form to
                </p>
                <p className="title fw800 poppins textDark">
                  Talk to an Instructor
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <input type="text" className="form-control" name="fname" placeholder="Full Name" value={formData.fname} onChange={handleChange} required />
                    </div>
                    <div className="col-md-6">
                      <input type="text" className="form-control" name="mobile" placeholder="Mobile Number" value={formData.mobile} onChange={handleChange} required />
                    </div>
                    <div className="col-md-12">
                      <input type="email" className="form-control" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="col-md-12">
                      <input type="text" className="form-control" name="phn" placeholder="Enter your WhatsApp Number" value={formData.phn} onChange={handleChange} required />
                    </div>
                    <div className="col-md-12">
                      <input type="date" className="form-control" name="inputDate" placeholder="Choose date for talking" value={formData.inputDate} onChange={handleChange} required />
                    </div>
                    <div className="col-md-12">
                      <textarea className="form-control" name="msg" id="inputMsg" placeholder="Type your Query" style={{ height: "120px" }} value={formData.msg} onChange={handleChange} required></textarea>
                    </div>
                  </div>
                  <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-5">
                    submit
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div className="rightImg">
                <img src={ConfusedImage} alt="#" loading="lazy" className="w100 h100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default TalkInstructor