import React from 'react'
import "./ClassAgendaStyle.css";
import CheckImg from "../assets/check-bg-dark.svg"
import ClassAgendaImage from "../assets/class-agenda.jpg"
import { Link } from 'react-scroll';
import {handleScroll} from '../utils/animPulse'
const ClassAgenda = () => {
  return (
    <div>
        <section className="masterClass lightBg ptop pbtm">
      <div className="container">
        <div className="masterClassCont">
          <div className="row align-items-center row-gap-4">
            <div className="col-lg-6 col-xl-7">
              <div className="videoArea">
                <img src={ClassAgendaImage} alt="#" loading="lazy" className="w100 h100 agendaImg" />
              </div>
            </div>
            <div className="col-lg-6 col-xl-5">
              <div className="rightCont">
                <h2 className="h2 fw700 poppins textDark mb-4">
                  The Class <span className="gradientText">Agenda</span>
                </h2>
                <ul className="resetUl">
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">
                      Understanding the connection between the mind, body & brain
                    </p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">
                      How to enter the Alpha state of mind
                    </p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Exercise to enter the Alpha state</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Benefits of using the Alpha level</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Power of affirmation</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Create your own affirmations</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Positive affirmation meditation</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Balancing emotions</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">A special guided meditation</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Reprogram the subconscious mind</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckImg} alt="#" loading="lazy" className="circleTick" />
                    <p className="textDark roboto fw500 mb-0">Doubt clearing session</p>
                  </li>
                </ul>
                <Link to="masterClassForm" smooth={true} duration={500}>
                <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0 shadowLight" onClick={()=>handleScroll()}>
                  book now
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default ClassAgenda