import React from 'react'
import './ExtraBonusStyle.css'
import bonusimg from '../assets/bonus-man.webp'

const ExtraBonus = () => {
    return (
        <>
            <section className='extraBonusSection lightBg ptop pbtm'>
                <div className='container'>
                    <div class="row align-items-center position-relative row-gap-4">
                        <div class="col-lg-6 col-xl-6">
                            <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-5">
                                কিছু অতিরিক্ত <span class="gradientText me-2">বোনাস</span>
                                যেগুলি আপনি পাবেন
                            </h2>
                            <ul className='resetUl'>
                                <li class="desc fw400 textDark poppins mb-4 d-flex align-items-start text-start">
                                    <span class="bonusPoints">বোনাস #১</span>
                                    সার্টিফায়েড সিলভা মেথড ইন্সট্রাক্টর দের সাথে যোগাযোগ করার সুযোগ পান
                                </li>
                                <li class="desc fw400 textDark poppins mb-4 d-flex align-items-start text-start">
                                    <span class="bonusPoints">বোনাস #২</span>
                                    আমাদের অফিসিয়াল হোয়াটস্যাপ গ্রুপের সদস্য
                                </li>
                                <li class="desc fw400 textDark poppins mb-4 d-flex align-items-start text-start">
                                    <span class="bonusPoints">বোনাস #৩</span>
                                    এই মাস্টারক্লাসে যোগ দিন এবং বিনামূল্যে গাইডেড মেডিটেশন অনুশীলন করুন
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-xl-6">
                            <div class="videoArea ms-auto">
                                <img src={bonusimg} alt="#" loading="lazy" class="w100 h100" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ExtraBonus