import React from 'react'
import Navbar2 from '../components/Navbar2'
import HealthHero from '../components/HealthHero'
import SickSection from '../components/SickSection'
import LearnMasterClass from '../components/LearnMasterClass'
import HappyLifeSection from '../components/HappyLifeSection'
import ExtraBonus from '../components/ExtraBonus'
import BenefitsHealth from '../components/BenefitsHealth'
import SilvaFounder from '../components/SilvaFounder'
import Footer2 from '../components/Footer2'
import Sticky from '../components/Sticky'
import AboutInstructor from '../components/AboutInstructor'

const HealthLanding = () => {
    return (
        <>
            <Navbar2 />
            <HealthHero />
            <SickSection />
            <LearnMasterClass />
            <HappyLifeSection />
            <ExtraBonus />
            <BenefitsHealth />
            <SilvaFounder />
            <AboutInstructor />
            <Footer2 />
            <Sticky />
        </>
    )
}

export default HealthLanding