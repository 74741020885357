import React from 'react'
import "./StickyStyle.css";
import CheckImg from "../assets/check-bg-dark.svg"
import { Link } from 'react-scroll';
import {handleScroll} from '../utils/animPulse'

const Sticky = () => {
  return (
    <div>
        {/* <section className="stickyCont d-md-none">
      <div className="container">
        <p className="title fw700 poppins primaryWhite mb-1">Join this Masterclass</p>
        <div className="d-flex align-items-center flex-wrap column-gap-4 row-gap-1">
          <p className="p2 fw900 roboto primaryWhite d-flex align-items-center justify-content-center mb-0">
            <span className="me-1">₹</span>
            <span className="roboto">99</span>
          </p>
          <p className="p2 fw900 oldPrice textLight2 mb-0">
            <span className="me-1">₹</span>
            <span className="roboto">198</span>
          </p>
          <Link to="masterClassForm" smooth={true} duration={500}>
          <button
            type="submit"
            className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape border-0 me-3"
            
          >
            BOOK NOW
          </button>
          </Link>
        </div>
      </div>
    </section> */}

    <section className="stickyCont d-md-none">
            <div className="container">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-1">
                    <div className="stickyLeft d-flex align-items-center column-gap-3 row-gap-1">
                        <p className="p2 fw900 roboto primaryWhite d-flex align-items-center justify-content-center mb-0">
                            <span className="me-1">₹</span>
                            <span className="roboto">99</span>
                        </p>
                        <p className="p2 fw900 oldPrice textLight2 mb-0">
                            <span className="me-1">₹</span>
                            <span className="roboto">198</span>
                        </p>
                    </div>
                    <div className="stickyRight">
                    <Link to="masterClassForm" smooth={true} duration={500}>
                        <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape border-0 me-3" onClick={()=>handleScroll()}>
                            save your spot
                        </button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Sticky