import React, { useEffect, useState } from 'react'
import "./HeroSectionC99Style.css";
import HeroBGImg from "../assets/99-LANDING-PAGE-66.webp"
import HeroBGMobileImg from "../assets/landing-banner-mob.webp"
import HeroBGTabImg from "../assets/landing-banner-tab.webp"
import CheckImg from "../assets/check-bg-white.svg"
import RazorpayImg from "../assets/RAZORPAY-ICON.webp"
import LockImg from "../assets/LOCK-SOLID-COLOUR.svg"
import LogoImg from "../assets/silva-india-logo-white.webp"
import LogoImg1 from "../assets/favicon-colorful.png"
import axios from 'axios';
import CheckYellowImg from "../assets/check-bg-yellow.svg"
import { useNavigate } from 'react-router-dom';
import useRazorpay from "react-razorpay";
import { loadRazorpay } from 'react-razorpay';
// const express = require('express');
// const cors = require('cors');
// const app = express();

// app.use(cors());

// Define your routes and other middleware...

export const HeroSectionC99 = () => {

  // const amount = 9900;
  // const currency = "INR";
  // const receipt = "";

  const navigate = useNavigate();


  // const [paymentLoading, setPaymentLoading] = useState(false);
  // const launchRazorpayPayment1 = async () => {

  //   setPaymentLoading(true);
  //   try {
  //     const res = await fetch('http://localhost:5000/order', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         amount: 99,
  //         currency: 'INR',
  //       }),
  //     });
  //     const order = await res.json();

  //     const options = {
  //       key: 'YOUR_RAZORPAY_KEY', // Replace with your Razorpay key
  //       amount: order.amount,
  //       currency: order.currency,
  //       order_id: order.id,
  //       name: 'Your Company Name',
  //       description: 'Payment for your order',
  //       handler: function (response) {
  //         alert(response.razorpay_payment_id);
  //         alert(response.razorpay_order_id);
  //         alert(response.razorpay_signature);
  //       },
  //       prefill: {
  //         name: 'Your Name',
  //         email: 'your.email@example.com',
  //         contact: '9999999999',
  //       },
  //       notes: {
  //         address: 'Your Address',
  //       },
  //       theme: {
  //         color: '#F37254',
  //       },
  //     };

  //     const razorpay = (await loadRazorpay()).default;
  //     const paymentObject = new razorpay(options);
  //     paymentObject.open();

  //     setPaymentLoading(false);
  //   } catch (error) {
  //     console.error('Error processing payment:', error);
  //     setPaymentLoading(false);
  //   }


  // }
  

    //ANKUR'S CODE FOR RAZORPAY PAYMENT-------------------------------------------------------------------------------------------------
  
    const launchRazorpayPayment2 = async () => {
      
      const response = await fetch ("https://silva-india-node-backend.onrender.com/order", {
        method: "POST",
        body: JSON.stringify({
          amount,
          currency,

        }),
        headers:{
          "Content-Type" : "application/json", 
        },
      });
      const order1 = await response.json();
      console.log(order1);

      var options = {
        "key": "rzp_test_RJvswW0ADyeF2K", // Enter the Key ID generated from the Dashboard
        "amount": "9900", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "The Silva Method India", //your business name
        "description": "Course99 Purchase",
        "image": "https://example.com/your_logo",
        "order_id": order1.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": function (response){
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature)
            window.location.href = '/thank-you';
        },
        "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
            "name": formData.fname, //your customer's name
            "email": formData.email, 
            "contact": formData.mobile  //Provide the customer's phone number for better conversion rates 
        },
        
        "notes": {
            "address": "The Silva Method India",
            "productId": 'course99', 
            "name" : formData.fname,
        },
        "theme": {
            "color": "#3399cc"
        }
    };
    var rzp1 = new Razorpay(options);
    rzp1.on('payment.failed', function (response){
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
    });
    rzp1.open();


   
  };
//----------------ANKUR CODE END--------------------------------------------------------------------------------------------------------------------------------

  // -----------------sukanta code----------------------------------------------
  const [Razorpay] = useRazorpay();
  const amount = 9900;
  const currency = "INR";
  const receipt = "";

  const [formData, setFormData] = useState({
    fname: '',
    mobile: '',
    email: '',
    timeslot: ''

  });

  const launchRazorpayPayment = async (formData) => {



    const paymentFormData = new FormData();
    paymentFormData.append("amount", "9900");

    const headers = new Headers();
    headers.append(
      "Authorization",
      "Bearer ace53c884b7dcfca2361173510f306be092e3296455a4baae1e3b58d9409fd3276282ef86724cda6670acf42f5b4cda5c0ab31fad155508ef79962edac4a5367302bf4ebb89790341ec573ae57b9cee7d0911b62fe3c772a90d9dfc22cddcc60dfca17128b3c0440aac87ff57b6f98fb8bfb721a3555e34058a97b2224eceac0"
    );
    headers.append("Content-Type", "application/json");

             
      const response = await fetch ("https://dedicated-nurture-a182b31812.strapiapp.com/api/orders", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(Object.fromEntries(paymentFormData)),
      });
       const order = await response.json();
       console.log(order);



       var options = {
        key:"rzp_test_RJvswW0ADyeF2K",
        amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency,
        handler: async function (response) {
            // Handle payment success here
            console.log("Payment successful:", response);
            var captureFormdata= new FormData();
            var captureHeaders= new Headers();

            captureHeaders.append(
                "Authorization",
                "Bearer ace53c884b7dcfca2361173510f306be092e3296455a4baae1e3b58d9409fd3276282ef86724cda6670acf42f5b4cda5c0ab31fad155508ef79962edac4a5367302bf4ebb89790341ec573ae57b9cee7d0911b62fe3c772a90d9dfc22cddcc60dfca17128b3c0440aac87ff57b6f98fb8bfb721a3555e34058a97b2224eceac0"
              );
              headers.append("Content-Type", "application/json");
          

            captureFormdata.append("amount", amount);
            captureFormdata.append("payment_id",response.razorpay_payment_id);
            captureFormdata.append("Order_Id", order?.paymentResult?.id );
            captureFormdata.append("productId","course99");
            captureFormdata.append("email",formData?.email);

            if(response.razorpay_payment_id){
                captureFormdata.append("status","success")
            }else{
                captureFormdata.append("status","failure")
            }
            // Capture payment on the client side
            try {
              const captureResponse = await fetch("https://dedicated-nurture-a182b31812.strapiapp.com/api/payments" , {
                method: "POST",
                headers: captureHeaders,
                body:  captureFormdata,
              });
              const captureData = await captureResponse.json();


              console.log("Capture response:", captureData);
              window.location.href = '/thank-you';
              // Perform further actions based on captureData if needed
            } catch (error) {
              console.error("Capture error:", error);
            }
          },
        "name": "Silva India", //your business name
        "description": "Test Transaction",
        "image": "https://example.com/your_logo",
        "order_id": order?.paymentResult?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
            "name": "customer name", //your customer's name
            "email": "customer email", 
            "contact": "customer phn"  //Provide the customer's phone number for better conversion rates 
        },
        "notes": {
            "address": "Razorpay Corporate Office"
            
        },
        "theme": {
            "color": "#3399cc"
        }
        };
        var rzp1 = new Razorpay(options);
        //  rzp1.on('payment.failed', function (response){
        //         alert(response.error.code);
        //         alert(response.error.description);
        //         alert(response.error.source);
        //         alert(response.error.step);
        //         alert(response.error.reason);
        //         alert(response.error.metadata.order_id);
        //         alert(response.error.metadata.payment_id);
        // });
       const rzpRes= rzp1.open();
       console.log(rzpRes,'rzp1.open');
    
};

// ----------------------sukanta code ends------------------------------------------------------------------------------------------------------

  const 
  launchRazorpayPayment11 = async () => {
    window.location.href = 'https://rzp.io/l/fr2ogx5';
  };



  //---------------code for form data submit----------------

 
  const [error, setError] = useState('');
  console.log('formdata', formData)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.fname || !formData.mobile || !formData.email || !formData.timeslot) {
      setError('All fields are required');
      return;
    }

    try {
      console.log('i am inside try');
      console.log('formdata', formData)

      // const response = await axios.post(
      //   'https://dedicated-nurture-a182b31812.strapiapp.com/api/landing-pages',
      //   {
      //     data: {
      //       Name: formData.fname,
      //       Phone: formData.mobile,
      //       Email: formData.email,
      //       TimeSlot: formData.timeslot
      //     }
      //   },
      //   {
      //     headers: {
      //       Authorization: 'Bearer b22c667d5e14a86c5f9c3fbbc9489b9028e05b3d7263a8c2531403ddfb91aaf51b0a30a88b2da909549932e07b125430f7ffc13b3f7ec4b88910fc498dfb0f04778127e33bfdb99543f9e678a1211b88cae385c64d7e93610f0aa6dd983b2c7bea7556c27e1cfc0cafeed6f671ced98b9fa7f5d8e6c501a86df53edf50521999'
      //       // Replace 'YOUR_API_TOKEN' with your actual API token
      //     }
      //   }
      // );

              const response = await fetch('https://silva-india-dashboard-backend.onrender.com/api/v1/leads/submit-landingpage-leads',{
                method: "POST",
                body: JSON.stringify({"name":formData.fname,
        "email":formData.email,
        "phone":formData.mobile,
        "timeslot":formData.timeslot
                               }),
                headers:{
                      "Content-Type": "application/json",
                }
               });


      console.log('Response:', await response.json());
      // Reset form fields after successful submission
      launchRazorpayPayment11(formData);
      setFormData({
        fname: '',
        mobile: '',
        email: '',
        timeslot: ''
      });
      setError('');
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred, please try again later.');
    }
  };



  //---------------------------------------------------------




  const isFormFilled = Object.values(formData).every((value) => value.trim() !== '');
  useEffect(() => {
    const btn = document.getElementById("btnSubmit");
    if (!isFormFilled) {
      btn.style.opacity = 0.4
    } else {
      btn.style.opacity = 1
    }
  }, [isFormFilled])


  const handleScroll = () => {
    const rightForm = document.querySelector(".rightForm");
    // console.log(doc, "document")
    if (rightForm) {
      rightForm.scrollIntoView({
        behavior: "smooth"
      })
    }
    setTimeout(() => {
      rightForm.classList.remove("pulseAnim");
      rightForm.classList.remove("pulseShadow");
    }, 4000)
    rightForm.classList.add("pulseAnim");
    rightForm.classList.add("pulseShadow");
  }

  const [isChecked, setIsChecked] = useState(true); // Set to true to make it checked by default

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state when the checkbox is clicked
  };


  return (
    <div>
      <section className="heroSection lightBg position-relative coursePage2">
        <img src={HeroBGMobileImg} className="d-md-none d-block w-100" alt="#" loading="lazy" />
        <img src={HeroBGTabImg} className="d-none d-md-block d-lg-none w-100" alt="#" loading="lazy" />
        <img src={HeroBGImg} className="d-none d-lg-block w-100 bannerLg" alt="#" loading="lazy" />
        <div className="carousel-caption">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center totalContArea">
              <div className="heroCont">
                <h1 class="h1 fw700 poppins colorYellow d-lg-none">
                  The Silva Method
                  <p class="mdFont fw600 subTitle mb-0 primaryWhite">
                    Your Guide to Overcome Life Problems
                  </p>
                </h1>
                <h1 class="h1 fw700 poppins primaryWhite d-none d-lg-block">
                  The Silva Method
                  <p class="mdFont fw600 subTitle mb-0 primaryWhite">
                    Your Guide to Overcome Life Problems
                  </p>
                </h1>
                <p className="fw400 roboto">
                  Are you feeling stuck in one of these problems:-
                </p>
                <ul className="resetUl heroPoints">
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckYellowImg} alt="#" loading="lazy"
                      class="circleTick d-lg-none" />
                    <img src={CheckImg} alt="#" loading="lazy"
                      class="circleTick d-none d-lg-block" />
                    <p className="primaryWhite roboto fw500 mb-0">Self-Victimization</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckYellowImg} alt="#" loading="lazy"
                      class="circleTick d-lg-none" />
                    <img src={CheckImg} alt="#" loading="lazy"
                      class="circleTick d-none d-lg-block" />
                    <p className="primaryWhite roboto fw500 mb-0">Emotional Imbalance</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckYellowImg} alt="#" loading="lazy"
                      class="circleTick d-lg-none" />
                    <img src={CheckImg} alt="#" loading="lazy"
                      class="circleTick d-none d-lg-block" />
                    <p className="primaryWhite roboto fw500 mb-0">Relationship Issues</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckYellowImg} alt="#" loading="lazy"
                      class="circleTick d-lg-none" />
                    <img src={CheckImg} alt="#" loading="lazy"
                      class="circleTick d-none d-lg-block" />
                    <p className="primaryWhite roboto fw500 mb-0">Procrastination</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckYellowImg} alt="#" loading="lazy"
                      class="circleTick d-lg-none" />
                    <img src={CheckImg} alt="#" loading="lazy"
                      class="circleTick d-none d-lg-block" />
                    <p className="primaryWhite roboto fw500 mb-0">Money Problem</p>
                  </li>
                </ul>
                <p className="fw400 roboto mt-4 mb-4">
                  Before these hurdles overshadow your happiness consider exploring The Silva Method - the
                  treasure chest of techniques to tackle life's challenges and reach your goals.
                </p>
                <div className="doubtsArea d-none d-lg-block">
                  <p class="title primaryWhite fw600 mb-2 poppins">Clear Your Doubts</p>
                  <p class="primaryWhite roboto">
                    Need clarification before making a purchase? Feel free to contact us at
                    <a href="#" class="fw600 colorYellow me-1 ms-1">
                      <span class="me-1"><i class="fa-solid fa-phone"></i></span>6290945413</a>
                    regarding the class. We're here to help you.
                  </p>
                  <a href="tel:6290945413" class="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                    call now
                  </a>
                </div>
                {/* <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                know more
              </a> */}
              </div>
              <div className="rightForm" id="masterClassForm">
                <div class="div1">
                  <p class="title fw700 poppins primaryBlack mb-1 p1">Join this Masterclass</p>
                  <span class="primaryBlack fw500 date">
                    Date <span class="fw700 shapeRect gradientBg primaryWhite">8th June</span>
                  </span>
                  <span class="primaryBlack fw500 ms-4">
                    Duration <span class="fw700 shapeRect gradientBg primaryWhite">3Hrs</span>
                  </span>
                </div>
                <form onSubmit={handleSubmit} >
                  {error && <div className="error">{error}</div>}
                  <div className="row gy-4">
                    <div className="col-12">
                      <input type="text" className="form-control" id="userName" name="fname" value={formData.fname} onChange={handleChange} placeholder="Full Name" />
                    </div>
                    <div className="col-12">
                      <input type="text" className="form-control" id="userPhn" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="Mobile Number" />
                    </div>
                    <div className="col-12">
                      <input type="email" className="form-control" id="userEmail" name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
                    </div>
                    <div className="col-12">
                      <select className="form-select form-control" id="selectTag" name="timeslot" value={formData.timeslot} onChange={handleChange} aria-label="Default select example">
                        <option value="" selected disabled>Book Time Slot</option>
                        <option value="10 a.m. - 1 p.m.">10 a.m. - 1 p.m.</option>
                        <option value="6:30 p.m. - 9:30 p.m">6:30 p.m. - 9:30 p.m.</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-wrap column-gap-4 row-gap-1 mt-3 mb-4 mb-md-3">
                    <p className="p2 fw900 roboto textDark d-flex align-items-center justify-content-center mb-0">
                      <span className="me-1">₹</span>
                      <span className="roboto">99</span>
                    </p>
                    <p className="p2 fw900 oldPrice textLight mb-0">
                      <span className="me-1">₹</span>
                      <span className="roboto">198</span>
                    </p>
                    <p className="fw600 roboto textDark mb-0">
                      You will get 50% Off
                    </p>
                  </div>
                  <div className="d-flex align-items-center flex-wrap column-gap-3 mb-4 paymentWidget" role="button">
                    <span className="fw600 textDark">Pay Via</span>
                    <img src={RazorpayImg} alt="#" loading="lazy" />
                  </div>
                  <ul class="resetUl">
                    <li class="d-flex align-items-start gap-2 mb-2">
                      <div class="form-check">
                        {/* <input class="form-check-input" type="checkbox" value=""
                          id="flexCheckChecked" checked />
                        <label class="form-check-label" for="flexCheckChecked">
                          <p class="textDark roboto mb-0 smFont">
                            I accept your <a href="#" class="fw500 textDark">Terms &
                              Conditions</a> and willingly agree to receive Marketing emails.
                          </p>
                        </label> */}
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="flexCheckChecked"
                            checked={isChecked} // Set checked attribute based on the state
                            onChange={handleCheckboxChange} // Call the function to handle checkbox change
                          />
                          <p className="textDark roboto mb-0 smFont">
                            I accept your <a href="#" className="fw500 textDark">Terms & Conditions</a> and willingly agree to receive Marketing emails.
                          </p>
                        </label>
                      </div>
                    </li>
                  </ul>
                  <button type="submit" id="btnSubmit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0" disabled={!isFormFilled}>
                    Enroll now
                  </button>
                  {/* <button type="button"  class="text-capitalize fw700 primaryBlack btnPulse">
                                    enroll now
                </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default HeroSectionC99