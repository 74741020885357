import React from 'react'
import './LearnMasterClassStyle.css'
import KalamImg from "../assets/KalamImg.webp"
import { Link } from 'react-scroll';

const LearnMasterClass = () => {
    return (
        <>
            <section className='LearnMasterClass silvaDiffSection ptop pbtm'>
                <div className='container'>
                    <div class="row align-items-center position-relative row-gap-4">
                        <div class="col-lg-6 col-xl-7">
                            <div class="videoArea"> 
                                <img src={KalamImg} alt="#" loading="lazy" class="w100 h100 agendaImg" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-5 text-center text-lg-start">
                            <h2 class="h2 fw600 poppins primaryWhite mb-3 mb-lg-4">
                                এই মাস্টারক্লাস থেকে আপনি কি কি শিখবেন
                            </h2>
                            <ul className='resetUl'>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src="/static/media/check-bg-white.4bb0d96dd8e9623085dc56e7668fd304.svg" alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">মন, শরীর, ও প্রকৃতির মধ্যে সমন্বয় সাধন করতে শিখুন</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src="/static/media/check-bg-white.4bb0d96dd8e9623085dc56e7668fd304.svg" alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">নিজের মনকে নিয়ন্ত্রনে রাখার উপায়</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src="/static/media/check-bg-white.4bb0d96dd8e9623085dc56e7668fd304.svg" alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">নিয়মিত মেডিটেশন কেন করবেন</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src="/static/media/check-bg-white.4bb0d96dd8e9623085dc56e7668fd304.svg" alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">ওষুধের সাহায্য ছাড়ায় মানসিক এবং শারীরিক সুস্থতা অর্জন</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src="/static/media/check-bg-white.4bb0d96dd8e9623085dc56e7668fd304.svg" alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">মনকে ব্যবহার করে দেহকে সুস্থ  রাখার উপায়</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src="/static/media/check-bg-white.4bb0d96dd8e9623085dc56e7668fd304.svg" alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">স্ট্রেস, রাগ, দুঃখ ইত্যাদি অনুভূতি গুলিকে নিয়ন্ত্রন করে সুখী থাকতে শিখুন</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src="/static/media/check-bg-white.4bb0d96dd8e9623085dc56e7668fd304.svg" alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">সুস্বাস্থ্য বজায় রাখার উপায়</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src="/static/media/check-bg-white.4bb0d96dd8e9623085dc56e7668fd304.svg" alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">রোগাক্রান্ত হয়ে পড়লে মনকে ব্যবহার করে রোগমুক্ত হন</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src="/static/media/check-bg-white.4bb0d96dd8e9623085dc56e7668fd304.svg" alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">স্বাস্থ্যকর জীবনযাপনের অভ্যাস কীভাবে গড়বেন</p>
                                </li>
                                <li class="d-flex align-items-start text-start gap-2 mb-2">
                                    <img src="/static/media/check-bg-white.4bb0d96dd8e9623085dc56e7668fd304.svg" alt="#" class="circleTick" />
                                    <p class="primaryWhite roboto fw300 mb-0">কিভাবে খারাপ অভ্যাস ত্যাগ করবেন</p>
                                </li>
                            </ul>
                            <Link to="masterClassForm_health" smooth={true} duration={500}>
                            <button type="submit" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0">
                                রেজিস্টার করুন
                            </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LearnMasterClass