import React from 'react'
import "../../components/WebinarWorksStyle.css";




const SlsWebinarWorks = ({data}) => {

  return (
    <div>
        <section className="webinarWorkSection bgImgProp" >
            <div className="container">
                <div className="webinarCont text-center">
                    <p className="title poppins primaryWhite fw500">
                        How Silva Method
                    </p>
                    <h2 className="h2 fw700 poppins primaryWhite text-center mb-4">
                        Free Webinar <span className="gradientText">Works</span>
                    </h2>
                    <p className="fw500 poppins primaryWhite p1">
                        If you know about the Silva Method Magic and wondering how to learn the Silva Method for free,
                        Then this is how it works!
                    </p>
                    <div className="cardsCont">
                        <div className="row gy-4">
                            {
                                data.map((item,i)=>
                                    <div className="col-md-6 col-lg-4">
                                <div className="cardItem">
                                    <div className="header bgImgProp"
                                        style={{backgroundImage: `url(${item?.img})`}}>
                                        <p className="title primaryWhite fw500 poppins mb-0">STEP- {i+1}</p>
                                    </div>
                                    <p className="bodyCont roboto primaryWhite fw400 mb-0 text-start">
                                        {item?.text}
                                    </p>
                                </div>
                            </div>
                                )
                            }
                          
                            {/* <div className="col-md-6 col-lg-4">
                                <div className="cardItem">
                                    <div className="header bgImgProp"
                                        style={{backgroundImage:`url(${WebinarCard_2_Img})`}}>
                                        <p className="title primaryWhite fw500 poppins mb-0">STEP- 2</p>
                                    </div>
                                    <p className="bodyCont roboto primaryWhite fw400 mb-0 text-start">
                                        Reach out to our Support Team to clear any doubt
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="cardItem">
                                    <div className="header bgImgProp"
                                        style={{backgroundImage:`url(${WebinarCard_3_Img})`}}>
                                        <p className="title primaryWhite fw500 poppins mb-0">STEP- 3</p>
                                    </div>
                                    <p className="bodyCont roboto primaryWhite fw400 mb-0 text-start">
                                        Fill out the form with your details and begin your transformation Journey
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default SlsWebinarWorks