import React from 'react'
import Form from '../components/Form'
import FAQ from '../components/FAQ'
import Newnavbar from '../components/Newnavbar'
import Footer from '../components/Footer'
import InstructorList from '../components/InstructorList'
import NewHeroSection from '../components/NewHeroSection'

import BannerImg from "../assets/instructor-header.webp"
import BannerMobileImg from "../assets/instructorpage-for-mobile.webp"
import BannerTabImg from "../assets/instructorpage-for-tab.webp"

const Instructor = () => {
  return (
    <div>
        <Newnavbar/>
        <NewHeroSection
        customClassName="aboutPage"
        bannerImg={BannerImg}
        bannerTabImg= {BannerTabImg}
        bannerMobileImg= {BannerMobileImg}
        title="Best Instructors Silva Method India"
        subtitle="We offer the best and certified Silva Method Instructors of India"
        description=""
        buttonText="Get Started"
        />
        <InstructorList/>
        <Form/>
        <FAQ/>
        <Footer/>
    </div>
  )
}

export default Instructor