import React from 'react'

import "./ClearDoubtStyle.css";
// import DynamicmeditationVideo from "../assets/dynamic-meditation.mp4"

const ClearDoubts = () => {
  return (
    <div>
        <section className="doubtsCall">
            <div className="container">
                <div className="doubtsArea darkOutline my-4 d-lg-none">
                    <p className="title textDark fw600 mb-2 poppins">Clear Your Doubts</p>
                    <p className="textDark roboto">
                        Need clarification before making a purchase? Feel free to contact us at
                        <a href="" className="fw600 gradientText">
                            <span className="me-1"><i className="fa-solid fa-phone"></i></span>6290945413</a>
                        regarding the class. We're here to help you.
                    </p>
                    <a href="tel:6290945413" className="btnMain primaryBlack fw500 primaryYellow text-capitalize shapeTrape">
                        call now
                    </a>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ClearDoubts