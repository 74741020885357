import React, { useState } from 'react'
import "./VideoTestimonialStyle.css";
import TestimonialVideo1 from "../assets/leaf.webp"
import ReactPlayer from 'react-player'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const VideoTestimonial = () => {

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 6,
    //     slidesToScroll: 2,
    //     responsive: [
    //         {
    //             breakpoint: 900,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 2,

    //             }
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 2,
    //             }
    //         }
    //     ]
    // };

    const videos = [
        'https://silva-method-official.b-cdn.net/Silva%20BD%20LP%20video/video-1.mp4',
        'https://silva-method-official.b-cdn.net/Silva%20BD%20LP%20video/video__2.mp4',
        'https://silva-method-official.b-cdn.net/Silva%20BD%20LP%20video/video-3.mp4',
        'https://silva-method-official.b-cdn.net/Silva%20BD%20LP%20video/video-4.mp4',
        'https://silva-method-official.b-cdn.net/Silva%20BD%20LP%20video/video-5.mp4',
        'https://silva-method-official.b-cdn.net/Silva%20BD%20LP%20video/video-6.mp4'
        // Add more video URLs as needed
    ];

    const [playing, setPlaying] = useState(new Array(videos.length).fill(false));


    const togglePlaying = (index) => {
        const updatedPlaying = [...playing];
        updatedPlaying[index] = !updatedPlaying[index]
        setPlaying(updatedPlaying);

    };

    return (
        <div className='testimonial'>
            <div className='container'>
                <h2 class="h2 fw700 poppins text-center textDark mb-4">Testimonials</h2>
                <div className='row'>
                    {videos.map((video, index) => (
                        <div className='col-lg-4 col-md-4 col-6'>
                            <div className="video-card w-100 mb-4" key={index}>
                                <ReactPlayer
                                    url={video}
                                    width={"93%"}
                                    playing={playing[index]}
                                    controls
                                    config={{
                                        youtube: {
                                            playerVars: { autoplay: 0 },
                                            youtube_dl: true, // Enable youtube-dl
                                            start: '10', // Extract thumbnail from 10 seconds
                                        },
                                    }}
                                />
                                {/* <button onClick={() => togglePlaying(index)}>
                                    {playing[index] ? 'Pause' : 'Play'}
                                </button> */}
                            </div>
                        </div>
                    ))}
                </div>


            </div>


            {/* <div className='container container2 d-lg-none'>
                <Slider {...settings}>

                    <div className='row'>
                        {videos.map((video, index) => (
                            <div className='col-6'>
                                <div className="video-card w-100 mb-4" key={index}>
                                    <ReactPlayer
                                        url={video}
                                        width={"93%"}
                                        playing={playing[index]}
                                        controls
                                        config={{
                                            youtube: {
                                                playerVars: { autoplay: 0 },
                                                youtube_dl: true, // Enable youtube-dl
                                                start: '10', // Extract thumbnail from 10 seconds
                                            },
                                        }}
                                    />
                                    <button onClick={() => togglePlaying(index)}>
                                    {playing[index] ? 'Pause' : 'Play'}
                                </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    
                </Slider>
            </div> */}
        </div>
    )
}

export default VideoTestimonial