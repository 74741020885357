import React from 'react'
import "./StrengthStyle.css";
import FooterBGImg from "../assets/footer-bg.webp"
import { Link } from 'react-scroll';
import {handleScroll} from '../utils/animPulse'
const Strength = () => {
  return (
    <div>
        <section className="strengthSection bgImgProp">
      <div className="container">
        <h2 className="h2 fw700 poppins primaryWhite text-center mb-5">
          Silva Mind Mastery: From Struggle to Strength
        </h2>
        <p className="primaryWhite roboto p1 mb-5">
          While one of your acquaintances enjoys a life brimming with happiness consistently, you find
          yourself struggling with physical and mental challenges that seem to compound with each passing day.
          But why? The main culprit is your limiting beliefs and your negative thought process. Your mind can
          be your best friend or your worst enemy. Although you might believe that you're in charge of your
          thoughts by staying aware, the truth is your subconscious governs 95% of your actions, recording
          everything and taking it literally. The Silva Mind Empowerment Method offers various meditation and
          mindfulness techniques to harness this powerhouse to reach your goals. That will help you to delve
          into the core of any issue, gather necessary information, and solve your life challenges
          effortlessly.
        </p>
        <div className="text-center">
        <Link to="masterClassForm" smooth={true} duration={500}>
          <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0" onClick={()=>handleScroll()}>
            enroll now
          </button>
          </Link>
        </div>
      </div>
    </section>
    </div>
  )
}

export default Strength