import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Form from '../components/Form'
import FAQ from '../components/FAQ'
import HeroSection_Courses from '../components/HeroSection_Courses'
import AboutBLS from '../components/AboutBLS'
import KeyPoints from '../components/KeyPoints'
import CourseSchedule from '../components/CourseSchedule'
import WebinarWorks from '../components/WebinarWorks'
import PersonalGrowth from '../components/PersonalGrowth'
import CoursesList from '../components/CoursesList'
import Newnavbar from '../components/Newnavbar'

const Courses = () => {
  return (
    <div>
    {/* <Navbar/> */}
    <Newnavbar/>
    <HeroSection_Courses/>
    <AboutBLS/>
    <KeyPoints/>
    <CourseSchedule/>
    <WebinarWorks/>
    <PersonalGrowth/>
    <CoursesList/>
    <Form/>
    <FAQ/>
    <Footer/>
    
    </div>
  )
}

export default Courses