import React from 'react'
import "./SilvaDifferenceStyle.css";
// import DynamicmeditationVideo from "../assets/dynamic-meditation.mp4"
import CheckImg from "../assets/check-bg-white.svg"
import ReactPlayer from 'react-player';
const DynamicmeditationVideo1 ="https://vz-6873ed28-3a2.b-cdn.net/216617ab-15c3-4c65-9c59-fdcc9c0b3724/playlist.m3u8"


const SilvaDifference = () => {
  return (
    <div>
        <section className="silvaDiffSection ptop pbtm">
      <div className="container">
        <h2 className="h2 fw700 poppins primaryWhite text-center mb-5">
          What Makes The Silva Method Unique
        </h2>
        <p className="primaryWhite roboto p1 mb-5 text-center m-auto">
          The Silva Method transcends traditional meditation, offering a holistic approach to self-discovery
          and inner exploration. Combining ancient mindfulness with modern science, this dynamic technique
          fosters personal growth and healing on multiple levels. For more than 50 years, this method has been
          changing lives. Over 12 million people around the world have used it to solve their problems and
          achieve success.
        </p>
        <div className="videoArea m-auto">
          {/* <video autoPlay muted loop width="100%" height="100%">
            <source src={DynamicmeditationVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
          <ReactPlayer
                  url={DynamicmeditationVideo1}
                  // controls
                  width="100%"
                  height="100%"
                  playing
                  muted
                  loop // Add the loop prop to enable looping
                  // config={{
                  //   hlsOptions: {
                  //     // You can add additional HLS options here if needed
                  //   }
                  // }}
                />
        </div>
        <div className="diffPoints">
          <div className="row">
            {/* Left Points */}
            <div className="col-md-6">
              <div className="leftPoints">
                <p className="title primaryWhite fw500 text-center poppins">
                  Passive Meditation
                </p>
                <ul className="resetUl">
                  <li className="d-flex align-items-start gap-2 m-4">
                    <img src={CheckImg} alt="#" className="circleTick" />
                    <p className="primaryWhite roboto fw300 mb-0">
                      In passive or traditional meditation, practitioners typically sit or lie
                      down in a comfortable position, often with closed eyes.
                    </p>
                  </li>
                  <li className="d-flex align-items-start gap-2 m-4">
                    <img src={CheckImg} alt="#" className="circleTick" />
                    <p className="primaryWhite roboto fw300 mb-0">
                    The primary focus is on calming the mind and achieving a state of deep relaxation.
                    </p>
                  </li>
                  <li className="d-flex align-items-start gap-2 m-4">
                    <img src={CheckImg} alt="#" className="circleTick" />
                    <p className="primaryWhite roboto fw300 mb-0">
                    The emphasis is on observing thoughts, feelings, and sensations without judgment and allowing them to pass by without attachment.
                    </p>
                  </li>
                  {/* Add other list items */}
                </ul>
              </div>
            </div>
            {/* Right Points */}
            <div className="col-md-6">
              <div className="rightPoints">
                <p className="title primaryWhite fw500 text-center poppins">
                  Dynamic Meditation
                </p>
                <ul className="resetUl">
                  <li className="d-flex align-items-start gap-2 m-4">
                    <img src={CheckImg} alt="#" className="circleTick" />
                    <p className="primaryWhite roboto fw300 mb-0">
                      Dynamic meditation involves movement, activity, or specific techniques
                      designed to energize the body and mind.
                    </p>
                  </li>
                  <li className="d-flex align-items-start gap-2 m-4">
                    <img src={CheckImg} alt="#" className="circleTick" />
                    <p className="primaryWhite roboto fw300 mb-0">
                    Unlike passive meditation, dynamic meditation may involve physical exercises, breathing techniques, or intense activities.
                    </p>
                  </li>
                  <li className="d-flex align-items-start gap-2 m-4">
                    <img src={CheckImg} alt="#" className="circleTick" />
                    <p className="primaryWhite roboto fw300 mb-0">
                    The aim of dynamic meditation is not only to achieve mental clarity and relaxation but also to stimulate energy flow, release tension, and cultivate vitality.
                    </p>
                  </li>
                  {/* Add other list items */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default SilvaDifference